<form [formGroup]="form" autocomplete="off">
  <ng-container>
    <div style="display: flex; flex-direction: row">
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select
          [(value)]="filterStatus"
          (valueChange)="handleStatusChanged($event)">
          <mat-option value="null">View All</mat-option>
          <mat-option value="scheduled">Scheduled</mat-option>
          <mat-option value="pending">Pending</mat-option>
          <mat-option value="completed">Completed</mat-option>
          <mat-option value="rejected">Rejected</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 20px">
        <mat-label>Assigned to</mat-label>
        <mat-select
          [(value)]="filterScheduledTo"
          (valueChange)="handleStatusChanged($event)">
          <mat-option value="">View All</mat-option>

          <mat-option *ngFor="let user of admins" [value]="user.id">{{
            user.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 20px">
        <mat-label>Action</mat-label>
        <mat-select
          [(value)]="filterActionType"
          (valueChange)="handleStatusChanged($event)">
          <mat-option value="">View All</mat-option>
          <mat-option *ngFor="let action of actions" [value]="action.id">{{
            action.description
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 20px">
        <mat-label>Received At</mat-label>
        <mat-date-range-input [formGroup]="form" [rangePicker]="receveidAtPicker"
        (dateChange)="handleDateChanged(dateRangeStart, dateRangeEnd)">
          <input matStartDate formControlName="receveidAtStart" placeholder="Start date" #dateRangeStart>
          <input matEndDate formControlName="receveidAtEnd" placeholder="End date" #dateRangeEnd>
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="receveidAtPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #receveidAtPicker></mat-date-range-picker>
        <!-- <mat-error *ngIf="form.controls.receveidAtStart.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="form.controls.receveidAtEnd.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 20px; margin-bottom: 20px;">
        <mat-label>Completed At</mat-label>
        <mat-date-range-input [formGroup]="form" [rangePicker]="completedAtPicker"
        (dateChange)="handleDateChanged(dateRangeStart, dateRangeEnd)">
          <input matStartDate formControlName="completedAtStart" placeholder="Start date" #dateRangeStart>
          <input matEndDate formControlName="completedAtEnd" placeholder="End date" #dateRangeEnd>
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="completedAtPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #completedAtPicker></mat-date-range-picker>
        <!-- <mat-error *ngIf="form.controls.receveidAtStart.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="form.controls.receveidAtEnd.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
      </mat-form-field>
    </div>

    <div class="sign-table" *ngIf="filterStatus">
      <!-- <div class="button-container">
          <button mat-raised-button color="primary" (click)="handleNew()">
            {{ "CREATE" }}
          </button>
        </div> -->
      <table
        class="mat-elevation-z8"
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)">
        <!-- Postal Code Column -->
        <ng-container matColumnDef="board.postalCode">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by Postal Code">
            <span class="text-bold">Postal Code</span>
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            {{ element.board?.postalCode }}
          </td>
        </ng-container>

        <!-- County Column -->
        <ng-container matColumnDef="board.city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">City</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.board?.city }}</td>
        </ng-container>

        <!-- House Number Column -->
        <ng-container matColumnDef="board.houseNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">House N#</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.board?.houseNumber }}
          </td>

          <td mat-footer-cell *matFooterCellDef [attr.colspan]="15">
            <mat-paginator
              #paginator
              class="table-paginator"
              (page)="handlePageEvent($event)"
              [length]="length"
              [pageSize]="pageSize"
              [disabled]="disabled"
              [showFirstLastButtons]="showFirstLastButtons"
              [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
              [hidePageSize]="hidePageSize"
              [pageIndex]="pageIndex"
              aria-label="Select page">
            </mat-paginator>
          </td>
        </ng-container>

        <!-- Street Column -->
        <ng-container matColumnDef="board.address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Street</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.board?.address }}
          </td>
        </ng-container>

        <!-- numBoards Column -->
        <ng-container matColumnDef="numBoards">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">N# Boards</span>
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            {{ element.numBoards }}
          </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="actionRequested">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-bold">Action</span>
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            <mat-chip style="transform: scale(0.8)" color="normal" selected>
              {{ prettyActionName(element.actionRequested) }}
            </mat-chip>
          </td>
        </ng-container>

        <!-- toRead Column -->
        <ng-container matColumnDef="toRead.description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">To Read</span>
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            {{ element.readSign }}
          </td>
        </ng-container>

        <!-- lastAction.receivedAt Column -->
        <ng-container matColumnDef="receivedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Received At</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.receivedAt | dateGBFormatter }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <span>
                <mat-chip
                  style="transform: scale(0.8)"
                  [color]="statusColor(element.status)"
                  selected>
                  {{ element.status }}
                </mat-chip>
              </span>
            </mat-chip-list>
          </td>
        </ng-container>

        <!-- createdBy Branch Column -->
        <ng-container matColumnDef="branch.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Branch</span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="white-space: nowrap"
            [matTooltip]="getUserName(element.board?.branch)">
            {{
              getUserName(element.board?.branch).length > 8
                ? (getUserName(element.board?.branch) | slice : 0 : 8) + '...'
                : getUserName(element.board?.branch)
            }}
            <!-- {{ getUserName(element.board?.branch) }} -->
          </td>
        </ng-container>

        <!-- createdBy Branch Column -->
        <ng-container matColumnDef="agent.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Agent</span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="white-space: nowrap"
            [matTooltip]="getUserName(element.board?.agent)">
            {{
              getUserName(element.board?.agent).length > 8
                ? (getUserName(element.board?.agent) | slice : 0 : 8) + '...'
                : getUserName(element.board?.agent)
            }}
          </td>
        </ng-container>

        <!-- completed At Column -->
        <ng-container matColumnDef="completedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Completed At</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.completedAt | dateGBFormatter }}
            <!-- <div style="display: flex; align-items: center">
              <button
                *ngIf="element.status == 'scheduled'"
                mat-mini-fab
                disabled="false"
                (click)="picker3.open()"
                [disabled]="element.status !== 'scheduled'">
                <mat-icon>event</mat-icon>
              </button>
              <span style="padding-left: 10px; white-space: nowrap">{{
                element.completedAt | DateToString
              }}</span>
            </div>

            <mat-form-field appearance="fill" style="display: none">
              <mat-label>Date</mat-label>
              <input
                matInput
                disabled
                [matDatepicker]="picker3"
                [value]="element.completedAt"
                (dateChange)="scheduleAtValueChange($event, element)" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker
                #picker3
                [disabled]="element.status !== 'scheduled'"></mat-datepicker>
            </mat-form-field> -->

            <mat-form-field appearance="fill" style="display: none">
              <mat-label>Date</mat-label>
              <input matInput disabled (value)="(element.completedAt)" />
              <mat-hint>MM/DD/YYYY</mat-hint>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- scheduleAt Column -->
        <ng-container matColumnDef="scheduledAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Scheduled At</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.scheduledAt | dateGBFormatter }}
            <!-- <div style="display: flex; align-items: center">
              <button
                *ngIf="element.status == 'pending'"
                mat-mini-fab
                disabled="false"
                (click)="picker3.open()"
                [disabled]="element.status !== 'pending'">
                <mat-icon>event</mat-icon>
              </button>
              <span style="padding-left: 10px; white-space: nowrap">{{
                element.scheduledAt | DateToString
              }}</span>
            </div>

            <mat-form-field appearance="fill" style="display: none">
              <mat-label>Date</mat-label>
              <input
                matInput
                disabled
                [matDatepicker]="picker3"
                [value]="element.scheduleAt"
                (dateChange)="scheduleAtValueChange($event, element)" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker
                #picker3
                [disabled]="element.status !== 'pending'"></mat-datepicker>
            </mat-form-field> -->
          </td>
        </ng-container>

        <!-- scheduleTo Column -->
        <ng-container matColumnDef="ba.scheduleTo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="text-bold">Assigned to</span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="white-space: nowrap"
            [matTooltip]="getUserName(element.scheduleTo)">
            {{
              getUserName(element.scheduleTo).length > 8
                ? (getUserName(element.scheduleTo) | slice : 0 : 8) + '...'
                : getUserName(element.scheduleTo)
            }}
          </td>
        </ng-container>

        <!-- Schedule Column -->
        <ng-container matColumnDef="schedule">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-bold">Schedule</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list *ngIf="element.status === 'pending'">
              <span>
                <mat-chip
                  [color]="element.status === 'pending' ? 'warn' : 'accent'"
                  selected
                  [disabled]="element.status !== 'pending'"
                  (click)="handleEdit(element)"
                  >{{ 'Schedule' }}</mat-chip
                >
              </span>
            </mat-chip-list>
          </td>
        </ng-container>

        <!-- Note Column -->
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-bold">Agent Notes</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.notes }}
          </td>
        </ng-container>

         <!-- Admin Note Column -->
         <ng-container matColumnDef="adminNotes">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-bold">Admin notes</span>
          </th>
          <td mat-cell *matCellDef="let element">
  
            {{ element.adminNotes }}
          </td>
        </ng-container>

         <!-- Delete Column -->
         <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-bold">Delete</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-mini-fab
              color="warn"
              disabled="false"
              (click)="openDialog(element.board.id, '0ms', '0ms')"
              style="transform: scale(0.75)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          matRipple
          *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsFooter"></tr>
      </table>
      <!-- <div class="table-paginator-wrapper">
        <mat-paginator
          #paginator
          class="table-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page">
        </mat-paginator>
      </div> -->
    </div>
  </ng-container>
</form>
