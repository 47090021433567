<div class="chartjs-block">
  <canvas
    baseChart
    [type]="'pie'"
    [datasets]="pieChartDatasets"
    [labels]="pieChartLabels"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend">
  </canvas>
</div>
