<h1>Latest News</h1>
<mat-accordion>

  <mat-expansion-panel (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Site Updates - 27/08/2023</b>
      </mat-panel-title>
      <mat-panel-description>
        Click here to {{panelOpenState ? 'hide' : 'show'}} release notes
      </mat-panel-description>
    </mat-expansion-panel-header>
    <h2>New features:</h2>
    <ul>
      <li>New Homepage: the landing page for the site has been set to give you latest news and messages about the system.</li>
      <li>Stray board feature now working: you can now raise stray board requests that do not require as much validation as normal board requests.</li>
      <li>Cancellation of changes and collections: you no longer need to email/phone to request job cancellations (as long as they haven't been scheduled). Cancellations can be carried out from the pending section on the "change/collect" screen.</li>
      <li>Board overview screen has been reworked: you can now click on any address to see the board activity historic for that specific address.</li>
    </ul>
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile>
        <figure>
          <img src="/assets/images/screenshots/news.png"/>
          <figcaption>Latest news screen</figcaption>
        </figure>
      </mat-grid-tile>
      <mat-grid-tile>
        <figure>
          <img src="/assets/images/screenshots/strays.png"/>
          <figcaption>New stray board screen</figcaption>
        </figure>
      </mat-grid-tile>
      <mat-grid-tile>
        <figure>
          <img src="/assets/images/screenshots/cancellations.png"/>
          <figcaption>Cancellation option under "Change/Collect"</figcaption>
        </figure>
      </mat-grid-tile>
      <mat-grid-tile>
        <figure>
          <img src="/assets/images/screenshots/overview.png"/>
          <figcaption>Overview screen re-worked</figcaption>
        </figure>
      </mat-grid-tile>
    </mat-grid-list>
    <p></p>
    <h2>Upcoming features:</h2>
    <ul>
      <li>Board activity screeen with month filter: this will allow branches to tally up board activity numbers against invoices received.</li>
    </ul>
  </mat-expansion-panel>
  
  </mat-accordion>
