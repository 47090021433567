import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Router } from '@angular/router';
import { has, isObject } from 'lodash-es';
import { SessionService } from 'src/app/auth/session.service';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { User } from 'src/app/core/user';
import { validateUUID } from 'src/app/core/utils';

@Component({
  selector: 'app-first-login-page',
  templateUrl: './first-login-page.component.html',
  styleUrls: ['./first-login-page.component.scss'],
})
export class FirstLoginPageComponent {
  loginForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  onSubmit() {
    if (this.loginForm.valid) {
      let loginData = this.loginForm.value;
      if (loginData.confirmPassword !== loginData.password) {
        this.loginForm.get('confirmPassword')?.setErrors({ incorrect: true });
      }

      this.sessionService.resetPassowrd(loginData.password || null).subscribe({
        next: user => {
          this.handleUser(user);
        },
        error: error => {
          this.toasterService.toast(
            ToastStatus.warning,
            'Please check your username and password Find ',
            'Login failed'
          );
        },
      });
    }
  }

  handleUser(user: Partial<User>) {
    if (isObject(user) && has(user, 'id') && validateUUID(user.id ?? '')) {
      this.router.navigate(['/']);
    }
  }
}
