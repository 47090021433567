<ng-container>
  <section>
    <mat-card>
      <mat-card-content>
        <div class="form-signin">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <h2
              class="h3 mb-3 font-weight-normal"
              style="display: flex; align-items: center">
              <mat-icon style="transform: scale(0.75)">lock</mat-icon
              ><span>Keeping your account secure</span>
            </h2>
            <h4>
              You need to update your password beacause this is the first time
              you are signing in, or because your password has expired.
            </h4>
            <mat-form-field>
              <input
                matInput
                placeholder="Password"
                formControlName="password"
                required
                type="password" />
              <mat-error *ngIf="!loginForm.controls.password.valid"
                >Please enter your new password, it should has more than 5
                characteres.</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Confirm Password"
                type="confirmPassword"
                formControlName="confirmPassword"
                required
                type="password" />
              <mat-error *ngIf="!loginForm.controls.confirmPassword.valid"
                >Passwords do not match</mat-error
              > </mat-form-field
            ><br />
            <button type="submit" mat-raised-button color="primary">
              Change Password
            </button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
</ng-container>
