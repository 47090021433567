<form [formGroup]="form" autocomplete="off">
  <ng-container>
    <div style="display: flex; flex-direction: row">
      <mat-form-field appearance="fill">
        <mat-label>Last Status</mat-label>
        <mat-select [(value)]="filterStatus" (valueChange)="handleStatusChanged($event)">
          <mat-option value="">View All</mat-option>
          <mat-option *ngFor="let status of status" [value]="status.id">{{
            status.description
            }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="margin-left: 20px;">
        <mat-label>Postcode search</mat-label>
        <input matInput placeholder="Ex. SW1W 0NY" value="" [formControl]="postalCodeControl">
      </mat-form-field>
    </div>


    <div>
      <!-- <div class="button-container">
        <button mat-raised-button color="primary" (click)="handleNew()">
          {{ "CREATE" }}
        </button>
      </div> -->
      <!-- <div>
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select
            [(value)]="filterStatus"
            (valueChange)="handleStatusChanged($event)">
            <mat-option value="null">View All</mat-option>
            <mat-option value="scheduled">Scheduled</mat-option>
            <mat-option value="pending">Pending</mat-option>
            <mat-option value="completed">Completed</mat-option>
            <mat-option value="rejected">Rejected</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="sign-table" *ngIf="true">
        <table class="mat-elevation-z8" mat-table [dataSource]="dataSource" matSort
          (matSortChange)="announceSortChange($event)" multiTemplateDataRows>

          <!-- House Number Column -->
          <ng-container matColumnDef="houseNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">House No</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.houseNumber }}
            </td>
          </ng-container>

          <!-- Street Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Street</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.address }}
            </td>
          </ng-container>

          <!-- County Column -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">City</span>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.city }}</td>
          </ng-container>

          <!-- Postal Code Column -->
          <ng-container matColumnDef="postalCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Postal Code">
              <span class="text-bold">Postcode</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ element.postalCode }}
            </td>

            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
              <mat-paginator #paginator class="table-paginator" (page)="handlePageEvent($event)" [length]="length"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex" aria-label="Select page">
              </mat-paginator>
            </td>
          </ng-container>

          <!-- numBoards Column -->
          <ng-container matColumnDef="numBoards">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">N# Boards</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ element.numBoards }}
            </td>
          </ng-container>

          <!-- action Column -->
          <ng-container matColumnDef="lastAction.actionRequested">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Last Action</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              <mat-chip style="transform: scale(0.8)" color="normal" selected>
                {{ prettyActionName(element.lastAction.actionRequested) }}
              </mat-chip>
            </td>
          </ng-container>

          <!-- toRead Column -->
          <ng-container matColumnDef="lastAction.toRead">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">To Read</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ prettyReadSign(element.lastAction.toRead)  }}
            </td>
          </ng-container>

          <!-- lastAction.receivedAt Column -->
          <ng-container matColumnDef="lastAction.receivedAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Received At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction.receivedAt | dateGBFormatter }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="lastActionStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Status</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list>
                <span>
                  <mat-chip [color]="
                      element.lastAction.status === 'completed'
                        ? 'primary'
                        : element.lastAction.status === 'scheduled'
                        ? 'accent'
                        : element.lastAction.status === 'rejected'
                        ? 'warn'
                        : 'blank'
                    " selected style="transform: scale(0.9)">
                    {{ element.lastAction.status }}
                  </mat-chip>
                </span>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- createdBy Branch Column -->
          <ng-container matColumnDef="branch.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Branch</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap"
              [matTooltip]="getUserName(element.branch)">
              {{
              getUserName(element.branch).length > 8
              ? (getUserName(element.branch) | slice : 0 : 8) + '...'
              : getUserName(element.branch)
              }}
              <!-- {{ getUserName(element.branch) }} -->
            </td>
          </ng-container>

          <!-- createdBy Agent Column -->
          <ng-container matColumnDef="agent.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Agent</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap"
              [matTooltip]="getUserName(element.agent)">
              {{
              getUserName(element.agent).length > 8
              ? (getUserName(element.agent) | slice : 0 : 8) + '...'
              : getUserName(element.agent)
              }}
            </td>
          </ng-container>

          <!-- completed At Column -->
          <ng-container matColumnDef="lastAction.completedAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Completed At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction.completedAt | dateGBFormatter }}
            </td>
          </ng-container>

          <!-- scheduleAt Column -->
          <ng-container matColumnDef="lastAction.scheduledAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Scheduled At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction.scheduledAt | dateGBFormatter }}
            </td>
          </ng-container>

          <!-- Note Column -->
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Agent Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button style="transform: 'scale(0.6)'" mat-mini-fab disabled="true" color="accent"
                [disabled]="!element.lastAction.notes" [matTooltip]="element.lastAction.notes"
                matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Note Column -->
          <ng-container matColumnDef="lastAction.adminNotes">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Admin Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button style="transform: 'scale(0.6)'" mat-mini-fab disabled="true" color="accent"
                [disabled]="!element.lastAction.adminNotes" [matTooltip]="element.lastAction.adminNotes"
                matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Edit</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-mini-fab color="accent" disabled="false"
                (click)="handleEdit(element.id, element.lastAction.status)" *ngIf="
                  element.lastAction.status === 'pending' &&
                  element.lastAction?.actionRequested === 'E'
                " style="transform: scale(0.75)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Cancel</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-mini-fab color="warn" disabled="false"
                (click)="handleDelete(element.id, element.lastAction.status)" *ngIf="
                  element.lastAction.status === 'pending' &&
                  element.lastAction?.actionRequested === 'E'
                " style="transform: scale(0.75)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="mat-elevation-z8">
                  <mat-table [dataSource]="element.actions">
                    <!-- Nested Table Columns -->
                    <ng-container matColumnDef="actionRequested">
            <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Action</span> </th>
            <td mat-cell *matCellDef="let action" style="white-space: nowrap">
              <mat-chip style="transform: scale(0.8)" color="normal" selected>
                {{ prettyActionName(action.actionRequested) }}
              </mat-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="receivedAt">
            <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Received At</span> </th>
            <td mat-cell *matCellDef="let action"> {{ action.receivedAt | dateGBFormatter }} </td>
          </ng-container>

          <ng-container matColumnDef="scheduleAt">
            <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Schedule At</span> </th>
            <td mat-cell *matCellDef="let action"> {{ action.scheduledAt | dateGBFormatter }} </td>
          </ng-container>

          <ng-container matColumnDef="completedAt">
            <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Completed At</span> </th>
            <td mat-cell *matCellDef="let action"> {{ action.completedAt | dateGBFormatter }} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Status</span> </th>
            <td mat-cell *matCellDef="let action">
              <mat-chip-list>
                <span>
                  <mat-chip [color]="
                            action.status === 'completed'
                                ? 'primary'
                                : action.status === 'scheduled'
                                ? 'accent'
                                : action.status === 'rejected'
                                ? 'warn'
                                : 'blank'
                            " selected style="transform: scale(0.9)">
                    {{ action.status }}
                  </mat-chip>
                </span>
              </mat-chip-list>
            </td>
          </ng-container>

          <ng-container matColumnDef="numBoards">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">N# of Boards</span>
            </th>
            <td mat-cell *matCellDef="let action" style="white-space: nowrap">
              {{ action.numBoards }}
            </td>
          </ng-container>

          <ng-container matColumnDef="toRead">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">To Read</span>
            </th>
            <td mat-cell *matCellDef="let action" style="white-space: nowrap">
              {{ prettyReadSign(action.toRead) }}
            </td>
          </ng-container>

          <!-- Note Column -->
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Agent Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button style="transform: 'scale(0.6)'" mat-mini-fab disabled="true" color="accent"
                [disabled]="!element.notes" [matTooltip]="element.notes" matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Admin Note Column -->
          <ng-container matColumnDef="adminNotes">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Admin Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button style="transform: 'scale(0.6)'" mat-mini-fab disabled="true" color="accent"
                [disabled]="!element.adminNotes" [matTooltip]="element.adminNotes" matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>


          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Cancel</span>
            </th>
            <td mat-cell *matCellDef="let action">
              <button mat-mini-fab color="warn" disabled="false" (click)="handleDeleteAction(action.id, action.status)"
                *ngIf="
                  action.status === 'pending' &&
                  action.actionRequested === 'E'
                  " style="transform: scale(0.75)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
          </mat-table>
      </div>
    </div>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="toggleRow(element)"></tr>
  <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
    [class.not-expanded-row]="expandedElement !== element"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumnsFooter"></tr>
  </table>
  </div>
  </div>
  </ng-container>
</form>
