import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collect-change-sign-table',
  templateUrl: './collect-change-sign-table.component.html',
  styleUrls: ['./collect-change-sign-table.component.scss'],
})
export class CollectChangeSignTableComponent {
  constructor() {}
}
