import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/auth/session.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  constructor(private router: Router, private sessionService: SessionService) {}

  ngOnInit(): void {
    this.logout();
  }
  
  navigateHandler() {
    this.router.navigate(['']).then(
      () => window.location.reload()
    )
  }

  logout() {
    this.sessionService.logout().subscribe(
      user => {
        console.warn('Logout sucessful');
      },
      err => {
        console.error(`[DSS layout:logout] ${err.message}`);
      }
    );
  }
}
