import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastStatus, ToasterService } from 'src/app/core/toaster/toaster.service';
import { SignService } from 'src/app/signs/sign.service';

@Component({
  selector: 'app-work-completed-confirm-rejected-dialog-component',
  templateUrl: './work-completed-confirm-rejected-dialog-component.component.html',
  styleUrls: ['./work-completed-confirm-rejected-dialog-component.component.scss']
})
export class WorkCompletedConfirmRejectedDialogComponentComponent implements OnDestroy {
  updateSignSubscription?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<WorkCompletedConfirmRejectedDialogComponentComponent>,
    private readonly signService: SignService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnDestroy(): void {
    if (this.updateSignSubscription) {
      this.updateSignSubscription.unsubscribe();
    }
  }

  handleCancel(): void {
    this.dialogRef.close();
  }

  handleConfirm() {
    
    debugger;
    let  boardActionIds: string[] = this.data.items;
    let handleError = (error: Error) => {
      // this.isLoading = false;
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
      this.dialogRef.close();
    };

    this.updateSignSubscription = this.signService
      .rejectBoardActionList({ boardActionIds })
      .subscribe({
        next: res => {
          // this.toasterService.toast(
          //   ToastStatus.success,
          //   'Schedule successfully created.'
          // );
          this.dialogRef.close();
          // this.router.navigate(['admin', 'complete-work']);
          window.location.reload();
        },
        error: error => handleError(error),
      });
  }

}
