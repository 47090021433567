import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  each,
  filter,
  assign,
  omit,
  hasIn,
  orderBy,
  get,
  isEmpty,
  isString,
} from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignTableConfirmDialogComponent } from '../sign-table-confirm-dialog/sign-table-confirm-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';


export interface SignAction{
  "id": string,
  "actionRequested": Action,
  "toRead": any,
  "receivedAt": string,
  "scheduledAt": string,
  "completedAt": string,
  "notes": string,
  "adminNotes": string|null,
  "numBoards": number,
  "isInvoiced": boolean,
  "scheduleTo": any,
  "status": string
  "createdAt": string
  "updatedAt": string,
}



export interface SignAction{
  "id": string,
  "actionRequested": Action,
  "toRead": any,
  "receivedAt": string,
  "scheduledAt": string,
  "completedAt": string,
  "notes": string,
  "adminNotes": string|null,
  "numBoards": number,
  "isInvoiced": boolean,
  "scheduleTo": any,
  "status": string
  "createdAt": string
  "updatedAt": string,
}


/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'app-sign-table',
  templateUrl: './sign-table.component.html',
  styleUrls: ['./sign-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('175ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SignTableComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  @ViewChildren('innerSort') innerSort: QueryList<MatSort> = new QueryList<MatSort>();
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<SignAction>> = new QueryList<MatTable<SignAction>>();


  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
  });

  displayedColumns: string[] = [];
  displayedColumnsFooter: string[] = [];
  // dataSource: MatTableDataSource<Sign> = new MatTableDataSource(undefined);
  dataSource: Sign[] = [];

  innerDisplayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  expandedElement: any | null = null;


  updateSignSubscription?: Subscription;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  sort: { active: string; direction: string } = {
    active: 'createdAt',
    direction: '',
  };

  filterStatus: BoardStatus | string = 'null';
  status: any[] = [
    { id: 'pending', description: 'Pending' },
    { id: 'completed', description: 'Completed' },
    { id: 'scheduled', description: 'Scheduled' },
  ];

  postalCodeControl = new FormControl('');
  filterPostalCode: string | null = null;
  

  constructor(
    private cd: ChangeDetectorRef,
    private signService: SignService,
    private router: Router,
    private readonly toasterService: ToasterService,
    public dialog: MatDialog
  ) { }

  openDialog(
    signId: string,
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(SignTableConfirmDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        signId,
      },
    });
  }

  ngOnInit(): void {
    this.postalCodeControl.valueChanges.subscribe(value => {
      this.filterPostalCode = value?.toUpperCase() ?? null;
      this.pageIndex = 0;
      this.fetchItems(this.pageSize, this.pageIndex);
    });

    this.displayedColumns = [
      'houseNumber',
      'address',
      'city',
      'postalCode',
      'lastAction.actionRequested',
      'lastActionStatus',
      'lastAction.toRead',
      /*       'branch.name', */
      // 'lastAction.receivedAt',
      // 'lastAction.scheduledAt',
      // 'lastAction.completedAt',
      // 'note',
      // 'lastAction.adminNotes',
      'edit',
      // 'delete'
    ];
    this.displayedColumnsFooter = ['postalCode'];

    this.fetchItems(this.pageSize, this.pageIndex);

    this.innerDisplayedColumns = ['actionRequested','status', 'numBoards', 'toRead', 'receivedAt', 'scheduleAt', 'completedAt', 'notes', 'adminNotes' ];

  }

  isExpanded(row: any): boolean {
    return this.expandedElement === row;
  }

  toggleRow(element: any) {
    console.log("IT WORKS ", element.actions)
    element.actions && (element.actions as MatTableDataSource<SignAction>) ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<SignAction>).sort = this.innerSort.toArray()[index]);
  }

  fetchItems(limit: number, page: number): void {
    let status = this.filterStatus === null ? null : this.filterStatus;

    // if(this.usingCollectSettings) {
    //   status = BoardStatus.completed
    // }
    let order =
      this.sort.direction === ''
        ? []
        : [`${this.sort.active} ${this.sort.direction.toUpperCase()}`];

    let lastActionStatus = this.filterStatus === null ? null : this.filterStatus;
    console.log("FONIXSS 2: ", this.filterPostalCode)

    this.signService
      .fetchUserAllBoard({ limit, page, orderBy: order, status, isActive: true, lastActionStatus, postalCode: this.filterPostalCode })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        // remove completed strays from board
        const items = value.items;
     
        each(items, item => {
          let cAt: Date = new Date(item.createdAt ?? '');
          // item.createdAt = this.enGBFormatter.format(cAt);
          item.readSign = this.prettyReadSign(item.toRead);

          item.actions = orderBy(item.actions, ['receivedAt'], ['desc']);
          item.lastAction = item.actions[0];
          if (item.lastAction) {
            item.lastAction.notes = get(item, 'lastAction.notes', '').trim();
            console.log("FUCK ACTION:; ", item.lastAction)
            // item.lastAction.adminNotes = get(item, 'lastAction.adminNotes', '').trim();
          }

          // if (item.schedule) {
          //   let sAt: Date = new Date(item.schedule.scheduleAt ?? '');
          //   item.scheduleAt = this.enGBFormatter.format(sAt);

          //   item.scheduleTo = item.schedule.worker;
          // }

          if (item.actionRequested) {
            item.action = this.signService.getBoardActionPrettyName(
              item.actionRequested
            );
          }
        });
        // this.dataSource = new MatTableDataSource(items);
        this.dataSource = items;
      });
  }

  prettyReadSign(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  statusColor(status: BoardStatus): string {
    switch (status) {
      case BoardStatus.scheduled:
        return 'accent';
      case BoardStatus.pending:
        return 'warn';
      case BoardStatus.completed:
        return 'primary';
      case BoardStatus.rejected:
        return 'warn';
    }
  }

  // export enum Action {
  //   erect = 'E',
  //   reerect = 'R',
  //   change = 'C',
  //   collect = 'COL',
  //   stray = 'STR'
  // }
  prettyActionName(action: Action): string {
    switch (action) {
      case Action.erect:
        return 'Erect';
      case Action.reerect:
        return 'Re-erect';
      case Action.change:
        return 'Change';
      case Action.collect:
        return 'Collect';
      case Action.stray:
        return 'Stray';
      default:
        return 'ERROR';
    }
  }

  getUserName(element: any) {
    return get(element, ['name'], '');
  }

  announceSortChange(sortState: any) {
    this.sort = sortState;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleStatusChanged(value: string) {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleEdit(signId: string, signStatus: string) {
    if (signStatus == 'pending') {
      this.router.navigate(['branch', 'request', 'sign', signId]);
    }
  }

  handleDelete(signId: string, signStatus: string) {
    if (signStatus == 'pending') {
      this.openDialog(signId, '0ms', '0ms');
    }
  }

  handleDeleteAction(actionId: string, signStatus: string) {
    // if (signStatus == 'pending') {
    //   this.openDialog(signId, '0ms', '0ms');
    // }
  }

  handleEdit2(signId: any) {
    // if (signStatus == 'pending') {
    //   this.router.navigate(['branch', 'sign', signId]);
    // }
  }

  handleShowNote(sign: Sign) {
    sign.hasNotes = true;
  }

  handleNew() {
    this.router.navigate(['branch', 'sign']);
  }

  handleChangeWorkType(element: Sign, newWorkType: string) {
    let handleError = (error: Error) => {
      // this.isLoading = false;
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
    };

    this.updateSignSubscription = this.signService
      .updateSign(
        assign(omit(element, ['createdBy']), { workType: newWorkType })
      )
      .subscribe({
        next: sign => {
          // this.isLoading = false;
          // console.log('Created! SIGN: ', sign);
          this.toasterService.toast(ToastStatus.success, 'Edit sucessful');
          this.router.navigate(['branch', 'request', 'change-table']);
          // this.fetchSigns()
          this.fetchItems(this.pageSize, this.pageIndex);
        },
        error: error => handleError(error),
      });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map(str => +str);
    }
  }
}
