import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  each,
  groupBy,
  split,
  find,
  hasIn,
  orderBy,
  toPairs,
  filter,
  includes,
  keys,
  get,
  assign,
  omit,
  map,
  every
} from 'lodash-es';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { MatStepper } from '@angular/material/stepper';
import { pluck } from 'rxjs';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import * as _ from 'lodash';
import { UserService } from 'src/app/core/user/user.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';

interface GroupedSigns {
  groupBy: string;
  signs: any[];
}

interface SelectValue {
  value: string;
  viewValue: string;
}

interface AgentList {
  position: number;
  agent: string;
}

interface SelectionModelForSignsByAgent {
  agent: string;
  selection: SelectionModel<Sign>;
}

@Component({
  selector: 'app-schedule-work-table-admin',
  templateUrl: './schedule-work-table-admin.component.html',
  styleUrls: ['./schedule-work-table-admin.component.scss'],
})
export class ScheduleWorkTableAdminComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pdfTable', { static: false })
  pdfTable!: ElementRef;

  @ViewChild('table') table!: MatTable<Sign>;
  displayedColumnsTable2: string[] = [
    'selectSigns',
    // 'position',
    'quantity',
    'board_numBoards',
    'actionRequested',
    'toRead.description',
    'houseNumber',
    'address',
    'city',
    'postalCode',
    'board.branch.name',
    'receivedAt',
    'note',
  ];
  displayedColumnsTable1: string[] = ['select', 'position', 'agent'];
  // dataSource = ELEMENT_DATA;

  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    // timeStyle: 'medium',
  });

  userSelectValues: SelectValue[] = [
    // {
    //   value: 'c88ac485-f823-4418-b1dd-e0e66a9af631',
    //   viewValue: 'Company Test',
    // },
  ];

  dateSelectValues: SelectValue[] = [];

  dataSource: MatTableDataSource<GroupedSigns> = new MatTableDataSource();

  dataSourceBySelectedDate: MatTableDataSource<AgentList> =
    new MatTableDataSource();

  dataSourceByPostalCode: MatTableDataSource<GroupedSigns> =
    new MatTableDataSource();

  today = new Date();

  currentAvailbableDates: string[] = [];

  @ViewChild('stepper', { static: false }) private stepper!: MatStepper;
  selectedStreamList = [0, 1, 2];

  selectionAgents = new SelectionModel<AgentList>(
    true,
    [],
    true,
    (otherValue, value) => otherValue.agent === value.agent
  );

  updateSignSubscription?: Subscription;
  selectedUser: string = '';
  selectedDate: string = '';


  selectionModelForSignsByAgent: SelectionModelForSignsByAgent[] = [];

  // selectedSigns = new SelectionModel<Sign>(
  //   true,
  //   [],
  //   true,
  //   (otherValue, value) => otherValue.id === value.id
  // );
  selectedScheduleAt: Date | undefined;

  isScheduleDone: boolean = false;

  constructor(
    private userService: UserService,
    private signService: SignService,
    private toasterService: ToasterService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router
  ) {
    this.router = router;
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  secondFormGroup = new FormGroup({
    secondCtrl: new FormControl(['', Validators.required]),
  });

  thirdFormGroup = new FormGroup({
    worker: new FormControl('', [Validators.required]),
    date: new FormControl(new Date(), [Validators.required]),
  });

  isLinear = true;

  scheduleAtFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6 && (d || new Date()) > new Date();
  };

  dropTable(index: number, event: CdkDragDrop<Sign[]>) {
    const prevIndex = this.dataSourceByPostalCode.data[index].signs.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(
      this.dataSourceByPostalCode.data[index].signs || [],
      prevIndex || 0,
      event.currentIndex
    );
    this.table.renderRows();
    console.log('NEW ORDER: ', this.dataSourceByPostalCode.data[index].signs);
  }

  dropTable1(event: CdkDragDrop<AgentList[]>) {
    const prevIndex = this.dataSourceBySelectedDate.data.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(
      this.dataSourceBySelectedDate.data,
      prevIndex,
      event.currentIndex
    );
    this.table.renderRows();
    console.log('NEW ORDER2: ', this.dataSourceBySelectedDate.data);
  }

  ngOnInit(): void {
    this.signService.fetchPendingBoardActions()
    .subscribe(fetchItems => {
      let groupByBranchs = groupBy(fetchItems, ba => ba.board.branch.username)

      let gsigns: GroupedSigns[] = [];
      each(groupByBranchs, (value, index) => {
        gsigns.push({
              groupBy: index,
              signs: value,
            })
      })
      this.dataSource = new MatTableDataSource(gsigns);

      let data: AgentList[] = [];
      each(gsigns, (gsign, index)  => {
        data.push({
          agent: gsign.groupBy,
          position: index
        })
      })

      this.dataSourceBySelectedDate = new MatTableDataSource(data);
    });

    this.userService.fetchAllAdmins().subscribe(users => {
      each(users.items, item => {
        this.userSelectValues.push({
          value: item.id,
          viewValue: item.name,
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.updateSignSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.stepper.selectionChange
      .pipe(pluck('selectedIndex'))
      .subscribe((res: number) => {
        console.log('STEP: ', res);
          if (res === 1) {
            // Signs Step
            let agents = map(this.selectionAgents.selected, 'agent');
            let allSigns: Sign[] = [];
            let signsToSelectedAgents: GroupedSigns[] =  filter(
              this.dataSource.data, 
              item => includes(agents, item.groupBy)
            );

            each(signsToSelectedAgents, item => {
              each(item.signs, sign => allSigns.push(sign))
            })

            let signsGrouped = groupBy(allSigns, action => {
              return split(action.board.postalCode, ' ')[0];
            });

            let selectedPostalCodes: string[] = [];
            each(this.selectionAgents.selected, (value, key) => {
              if (value.agent) {
                selectedPostalCodes.push(value.agent);
                this.selectionModelForSignsByAgent.push(
                  {
                    agent: value.agent,
                    selection: new SelectionModel<Sign>(
                      true,
                      [],
                      true,
                      (otherValue, value) => otherValue.id === value.id
                    )
                  }
                )
              }
            });

            this.dataSourceByPostalCode = new MatTableDataSource(signsToSelectedAgents);
            console.log('STEP 2: ', this.dataSourceByPostalCode.data);
          }
        });
      }

  getUserName(element: any) {
    return `  ${get(element, ['username'], '')}  `;
  }

  prettyToRead(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  scheduleDateSelected(value: string) {
    console.log('VAAALUE: ', value);
  }

  formatDate(date: string | undefined): string {
    if (!date) {
      return '';
    }

    var d = new Date(date),
      month = '' + (d!.getMonth() + 1),
      day = '' + d!.getDate(),
      year = d!.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedPostalCode() {
    const numSelected = this.selectionAgents.selected.length;
    const numRows = this.dataSourceBySelectedDate.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRowsPostalCode() {
    if (this.isAllSelectedPostalCode()) {
      this.selectionAgents.clear();
      return;
    }

    this.selectionAgents.select(...this.dataSourceBySelectedDate.data);
  }

  selectBoardsSchedule() {
    if (this.thirdFormGroup.invalid) {
      return;
    }
  }

  changeNumBoardsSelected(element: Sign, value: any) {
    console.log('ELEMENT: ', element);
    console.log('NUM BOARDS: ', value);
    element.numBoards = parseInt(value);
  }


  generateSchedule() {
    if (this.thirdFormGroup.invalid) {
      return;
    }

    let handleError = (error: Error) => {
      // this.isLoading = false;
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
    };

    const selectedSigns: Sign[] = [];
    each(this.selectionModelForSignsByAgent, (value) => {
      each(value.selection.selected, (sign) => {
        selectedSigns.push(sign);
      })
    })

    this.updateSignSubscription = this.signService
      .scheduleBoardActions({
        actions: map(selectedSigns, (sign) => {
          return { id: sign.id, newQuantity: sign.numBoards }
        }),
        scheduleAt: this.thirdFormGroup.value.date?.toISOString(),
        userId: this.thirdFormGroup.value.worker?.toString(),
      })
      .subscribe({
        next: res => {
          // this.isLoading = false;
          // console.log('Created! SIGN: ', sign);
          this.toasterService.toast(
            ToastStatus.success,
            'Schedule successfully created.'
          );
          this.isScheduleDone = true;
          this.router.navigate(['admin', 'signs']);
          // this.fetchSigns()
          // this.fetchItems(this.pageSize, this.pageIndex);
        },
        error: error => handleError(error),
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedSigns(group: GroupedSigns) {
    let current = _.find(this.selectionModelForSignsByAgent, { agent: group.groupBy });

    if (!(current?.selection.hasValue())) return false;

    let filteredSelected: Sign[] = [];
    _.each(current?.selection?.selected, (sign) => {
      if (_.includes(group.signs, sign)) {
        filteredSelected.push(sign);
      }
    });
    return _.difference(filteredSelected, group.signs).length === 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRowsSigns(group: GroupedSigns) {
    let current = _.find(this.selectionModelForSignsByAgent, { agent: group.groupBy });

    if (this.isAllSelectedSigns(group)) {
      current?.selection.clear();
      return;
    }

    current?.selection.select(...group.signs);
  }

  handleSelectionChangeSigns(group: GroupedSigns, row: any) {
    let current = _.find(this.selectionModelForSignsByAgent, { agent: group.groupBy });
    current?.selection.toggle(row);
  }

  handleIsSelectedSign(group: GroupedSigns, row: any) {
    let current = _.find(this.selectionModelForSignsByAgent, { agent: group.groupBy });
    return current?.selection.isSelected(row);
  }

  handleIsIndeterminateSign(group: GroupedSigns) {
    let current = _.find(this.selectionModelForSignsByAgent, { agent: group.groupBy });
    return current?.selection.hasValue() && current?.selection.selected.length > 0;
  }

  locationReload() {
    window.location.reload();
  }
}
