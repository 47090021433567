import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GeofencingService,
  LatLng,
} from 'src/app/components/google-map/geofencing.service';
import {
  reduce,
  assign,
  isEmpty,
  pick,
  trim,
  has,
  hasIn,
  isArray,
  orderBy,
  get,
  unset,
  first,
} from 'lodash-es';
import { SignService, Sign } from 'src/app/signs/sign.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { SessionService } from 'src/app/auth/session.service';
import { Subscription } from 'rxjs';

interface SelectValue {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-sign',
  templateUrl: './create-sign.component.html',
  styleUrls: ['./create-sign.component.scss'],
})
export class CreateSignComponent implements OnInit, OnDestroy {
  @Input() usingStraySettings: boolean = false;

  isLoading: boolean = false;

  signId: string | null = null;

  isEditing: boolean = false;

  toReads: any[] = [
    // { value: 'sale', viewValue: 'For Sale' },
    // { value: 'sold', viewValue: 'Sold' },
    // { value: 'to_let', viewValue: 'To Let' },
    // { value: 'let_by', viewValue: 'Let By' },
  ];

  works: SelectValue[] = [
    { value: 'new', viewValue: 'new' },
    { value: 'reerect', viewValue: 'Re-Erect' },
    { value: 'change', viewValue: 'Change' },
    { value: 'collect', viewValue: 'Collect' },
  ];

  UKPostCodePattern =
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/;

  createSignForm = new FormGroup({
    houseNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(25),
    ]),
    address: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(36),
    ]),
    city: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(58),
    ]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(8),
      // Validators.pattern(this.UKPostCodePattern),
    ]),
    toRead: new FormControl('', [Validators.required]),
    workType: new FormControl('E', []),
    notes: new FormControl('', [Validators.maxLength(256)]),
    numBoards: new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(10),
    ]),
  });

  currentSignForm = this.createSignForm;

  markerLatLng?: LatLng;

  currentEditBoard?: any;

  editAction?: string;

  constructor(
    private readonly geofencingService: GeofencingService,
    private readonly signService: SignService,
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toasterService: ToasterService
  ) { }

  geoMarkerLatLngSubscription?: Subscription;
  geoMarkerAddressSubscription?: Subscription;
  fetchSignSubscription?: Subscription;
  createSignSubscription?: Subscription;
  updateSignSubscription?: Subscription;

  ngOnInit(): void {
    console.log("FUCK THIS: ", this.usingStraySettings)

   if(this.usingStraySettings) {
    this.createSignForm.get('toRead')?.setValidators([
      Validators.required,
    ])
    this.createSignForm.get('address')?.setValidators([])
    this.createSignForm.get('city')?.setValidators([])
    this.createSignForm.get('postalCode')?.setValidators([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(8),
    ])
   }
    this.route.queryParams.subscribe(params => {
      this.editAction = params['action'];
    });

    let userLogged = this.sessionService.getUser();
    if (
      hasIn(userLogged, 'toReadGroup.toReads') &&
      isArray(userLogged.toReadGroup?.toReads)
    ) {
      this.toReads = userLogged.toReadGroup?.toReads;
      this.createSignForm.patchValue({toRead: first(this.toReads)?.id || null})
    }

    this.geoMarkerLatLngSubscription =
      this.geofencingService.selectedGeoMarkerLatLng$.subscribe(value => {
        console.log('actualizou marker no form: ', value);
        if (value.lat && value.lng) {
          this.markerLatLng = value;
        } else {
          this.markerLatLng = undefined;
        }
      });

    this.geoMarkerAddressSubscription =
      this.geofencingService.selectedGeoMarkerAddress$.subscribe(value => {
        console.log(
          'CreateSignComponent selectedGeoMarkerAddress.sub.next: ',
          value
        );
        this.currentSignForm.patchValue({
          address: value.street,
          city: value.city,
        });
      });

    this.getSign();
  }

  ngOnDestroy(): void {
    this.geoMarkerLatLngSubscription?.unsubscribe();
    this.geoMarkerAddressSubscription?.unsubscribe();
    this.fetchSignSubscription?.unsubscribe();
    this.createSignSubscription?.unsubscribe();
    this.updateSignSubscription?.unsubscribe();

    this.geofencingService.setGeoString('');
    this.geofencingService.setGeoMarkerAddress({
      city: '',
      street: '',
      postalCode: '',
    });
  }

  toReadValueChange(value: string) {
    this.currentSignForm.patchValue({ toRead: value });
  }

  workSignValueChange(value: string) {
    this.currentSignForm.patchValue({ workType: value });
  }

  notesValueChange(value: string) {
    this.currentSignForm.patchValue({ notes: value });
  }

  getSign(): void {
    this.signId = this.route.snapshot.paramMap.get('id');
    this.isEditing = false;
    this.currentEditBoard = null;
    if (this.signId && this.signId != '') {
      this.isEditing = true;
      this.fetchSignSubscription = this.signService
        .fetchSignById(this.signId)
        .subscribe(value => {
          value.actions = orderBy(value.actions, ['receivedAt'], ['desc']);
          value.lastAction = value.actions[0];
          console.log('value: ', value);
          this.currentSignForm.patchValue({
            city: value.city,
            address: value.address,
            postalCode: value.postalCode,
            houseNumber: value.houseNumber,
            toRead: value.lastAction.toRead.id,
            workType: value.lastAction.actionRequested,
            notes: value.lastAction.notes,
          });
          this.currentEditBoard = value;
          if (!this.editAction && value.lastAction.status !== 'pending') {
            this.createSignForm.disable();
          }
          if (this.editAction == undefined) {
          } else if (this.editAction == 'reerect') {
            console.log('Editing reerect...');
            this.createSignForm.controls['houseNumber'].disable();
            this.createSignForm.controls['address'].disable();
            this.createSignForm.controls['city'].disable();
            this.createSignForm.controls['postalCode'].disable();
          } else if (this.editAction == 'change') {
            this.createSignForm.controls['houseNumber'].disable();
            this.createSignForm.controls['address'].disable();
            this.createSignForm.controls['city'].disable();
            this.createSignForm.controls['postalCode'].disable();
          } else if (this.editAction == 'collect') {
            this.createSignForm.controls['houseNumber'].disable();
            this.createSignForm.controls['address'].disable();
            this.createSignForm.controls['city'].disable();
            this.createSignForm.controls['postalCode'].disable();
            this.createSignForm.controls['toRead'].disable();
          } else {
            console.log('Editing other shit...');
            // disabled board edit fields
            this.createSignForm.disable();
          }

          this.markerLatLng = value.markerLatLng;
          console.log('FETCHED markerLatLng', this.markerLatLng);
          console.log('EDIT ACTION: ', this.editAction);
          // this.geofencingService.setGeoString(this.generateGeoString());

          if (this.markerLatLng) {
            this.geofencingService.setGeoMarkerLatLng(this.markerLatLng!);
          }

          // this.buttonFindGeoTag();
        });
    }
  }

  // postCodeValidator(control: FormControl) {
  //   let givenPostCode = control.value;

  //   let UKPostCodePattern =
  //     /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))s?[0-9][A-Za-z]{2}))$/;
  //   var isUKPostCodeValid = UKPostCodePattern.test(givenPostCode);

  //   console.log(
  //     'postcode validity ',
  //     isUKPostCodeValid,
  //     ' for ',
  //     givenPostCode
  //   );

  //   if (!isUKPostCodeValid) {
  //     return {
  //       postCode: {
  //         required: 'UK Valid PostCode',
  //         provided: givenPostCode,
  //       },
  //     };
  //   }
  //   return null;
  // }

  generateGeoString(): string {
    return reduce(
      pick(this.currentSignForm.value, [
        'postalCode',
        // 'city',
        // 'address',
        // 'houseNumber',
      ]),
      (sum, n) => {
        return !isEmpty(n) ? `${trim(n ?? '')} ${trim(sum)}` : `${trim(sum)}`;
      },
      ''
    );
  }

  changeSelected(purgeForm: boolean = false) {
    if (purgeForm) {
      this.currentSignForm.patchValue({
        houseNumber: '',
        address: '',
        city: '',
      });
    }

    // Set GeoString to search using Geofencing API

    console.log('CALLED changeSelected GEOSTRING');
    // this.geofencingService.setGeoString(this.generateGeoString());
  }

  buttonFindGeoTag() {
    this.geofencingService.setGeoString(this.generateGeoString());
  }

  // Handle submit/create button
  handleCreate() {
    this.currentSignForm.markAllAsTouched();
    debugger;
    if (this.currentSignForm.valid) {
      this.isLoading = true;

      let handleError = (error: Error) => {
        this.isLoading = false;
        this.toasterService.toast(ToastStatus.error, 'Try again later.');
        console.error('Error!: ', error);
      };

      if (this.isEditing) {
        // this.currentEditBoard.houseNumber = trim(this.currentSignForm.value.houseNumber ?? '')
        // this.currentEditBoard.address = trim(this.currentSignForm.value.address ?? '')
        // this.currentEditBoard.city = trim(this.currentSignForm.value.city ?? '')
        // this.currentEditBoard.postalCode = trim(this.currentSignForm.value.postalCode ?? '')

        // Board Action
        this.currentEditBoard.lastAction.toRead =
          this.currentSignForm.value.toRead ?? this.currentEditBoard.lastAction.toRead;
        this.currentEditBoard.lastAction.numBoards =
          this.currentSignForm.value.numBoards ?? 1;
        this.currentEditBoard.lastAction.notes =
          this.currentSignForm.value.notes ?? '';

        // Board

        let updateData = {
          action: this.editAction ? this.editAction : 'update',
          boardAction: {
            toRead: this.currentSignForm.value.toRead ?? this.currentEditBoard.lastAction.toRead.id,
            numBoards: this.currentSignForm.value.numBoards ?? 1,
            notes: this.currentSignForm.value.notes ?? '',
          },
          board: {
            houseNumber: trim(this.currentSignForm.value.houseNumber ?? ''),
            address: trim(this.currentSignForm.value.address ?? ''),
            city: trim(this.currentSignForm.value.city ?? ''),
            postalCode: trim(this.currentSignForm.value.postalCode ?? ''),
            markerLatLng: this.markerLatLng,
          },
        };

        if (updateData.action != 'update') {
          unset(updateData, 'board');
        }

        console.log('UPDATE DATA', updateData);
        this.updateSignSubscription = this.signService
          .updateBoardAndAction(
            this.currentEditBoard.id,
            this.currentEditBoard.lastAction.id,
            updateData
          )
          .subscribe({
            next: result => {
              this.isLoading = false;
              console.log('Updated Board Action! Res: ', result);
              this.toasterService.toast(
                ToastStatus.success,
                'Edit successful!'
              );
              if (this.editAction == undefined) {
                this.router.navigate(['branch', 'signs']);
              } else if (this.editAction == 'update') {
                this.router.navigate(['branch', 'signs']);
              } else {
                this.router.navigate(['branch', 'request', 'change-table']);
              }
            },
            error: error => handleError(error),
          });

        // console.log('NO EDIT', this.currentEditBoard);
        // this.updateSignSubscription = this.signService
        //   .updateBoardAction(
        //     this.currentEditBoard.lastAction.id,
        //     this.currentEditBoard.lastAction
        //   )
        //   .subscribe({
        //     next: result => {
        //       this.isLoading = false;
        //       console.log('Updated Board Action! Res: ', result);
        //       this.toasterService.toast(
        //         ToastStatus.success,
        //         'Edit successful!'
        //       );
        //       this.router.navigate(['branch', 'signs']);
        //     },
        //     error: error => handleError(error),
        //   });
      } else {
        let newSign: Sign = {
          houseNumber: trim(this.currentSignForm.value.houseNumber ?? ''),
          address: trim(this.currentSignForm.value.address ?? ''),
          city: trim(this.currentSignForm.value.city ?? ''),
          postalCode: trim(this.currentSignForm.value.postalCode ?? ''),
          toRead: this.currentSignForm.value.toRead ?? '',
          markerLatLng: this.markerLatLng ?? {},
          workType: this.currentSignForm.value.workType ?? '',
          notes: this.currentSignForm.value.notes ?? '',
          numBoards: this.currentSignForm.value.numBoards ?? 1
        };
        let userLogged = this.sessionService.getUser();

        if(!this.usingStraySettings) {
          this.createSignSubscription = this.signService
          .createSignErect(assign({}, newSign, { createdBy: userLogged.id }))
          .subscribe({
            next: sign => {
              this.isLoading = false;
              console.log('Created! SIGN: ', sign);
              this.toasterService.toast(
                ToastStatus.success,
                'Created sucessful'
              );
              this.router.navigate(['branch', 'signs']);
            },
            error: error => handleError(error),
          });
        } else  {
          this.createSignSubscription = this.signService
          .createSignStray(assign({}, newSign, { createdBy: userLogged.id }))
          .subscribe({
            next: sign => {
              this.isLoading = false;
              console.log('Created! SIGN: ', sign);
              this.toasterService.toast(
                ToastStatus.success,
                'Created stray board sucessful'
              );
              this.router.navigate(['branch', 'signs']);
            },
            error: error => handleError(error),
          });
        }
       
      }
    }
  }
}
