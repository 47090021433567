import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { SessionService, UserRole } from 'src/app/auth/session.service';
import { ToasterService, ToastStatus } from 'src/app/core/toaster/toaster.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  // animations: [
  //   trigger('indicatorRotate', [
  //     state('collapsed', style({transform: 'rotate(0deg)'})),
  //     state('expanded', style({transform: 'rotate(180deg)', scaleX: -1})),
  //     transition('expanded <=> collapsed',
  //       animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
  //     ),
  //   ])
  // ]
})
export class LayoutComponent implements OnInit {
  username: string = '';
  chatBadgeisHidden: boolean = false;
  notificationBadgeisHidden: boolean = false;

  isImpersonateEnabled = this.sessionService.isImpersonate();

  menu = [
    {
      link: 'admin/dashboard',
      label: 'Upload',
      icon: '../../assets/icons/dashboard.svg',
      iconSelected: '../../assets/icons/dashboard-selected.svg',
      selected: true,
    },
    // {
    //   link: 'admin/business',
    //   label: 'Execute',
    //   icon: '../../assets/icons/business.svg',
    //   iconSelected: '../../assets/icons/business-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/freelancers',
    //   label: 'Freelancers',
    //   icon: '../../assets/icons/freelancers.svg',
    //   iconSelected: '../../assets/icons/freelancers-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/companies',
    //   label: 'Companies',
    //   icon: '../../assets/icons/companies.svg',
    //   iconSelected: '../../assets/icons/companies-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/payments',
    //   label: 'Payments',
    //   icon: '../../assets/icons/payments.svg',
    //   iconSelected: '../../assets/icons/payments-selected.svg',
    //   selected: false,
    // },
  ];

  options = {
    bottom: 0,
    fixed: true,
    top: 0,
  };

  links = [
    // {
    //   link: 'dashboard',
    //   label: 'Dashboard',
    //   icon: 'home_pin',
    //   children: [],
    // },
    {
      link: 'homepage',
      label: 'Homepage',
      icon: 'home_pin',
      children: [],
    },
    // {
    //   link: 'homepage',
    //   label: 'Completed Activities',
    //   icon: 'share_location',
    //   children: [],
    // },
    {
      link: 'signs',
      label: 'Properties',
      icon: 'flag_circle',
      children: [],
    },
    // {
    //   link: '',
    //   label: 'Board Request',
    //   icon: 'add_location',
    //   expanded: false,
    //   children: [

    {
      link: 'request/sign',
      label: 'New Board',
      icon: 'add_home',
      children: [],
    },
    {
      link: 'request/change-table',
      label: 'Collect/Change',
      icon: 'follow_the_signs',
      children: [],
    },
    {
      link: 'request/collect',
      label: 'Collect Stray',
      icon: 'wrong_location',
      children: [],
    },
    //   ],
    // },
    // { link: '/agents', label: 'Agents', icon: 'manage_accounts' },
    // {
    //   link: '/signs',
    //   label: 'Work Schedule',
    //   icon: 'signpost',
    // },
  ];

  // private router: Router;
  // private route: ActivatedRoute;

  constructor(
    private readonly router: Router, // private session: SessionService,
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionService,
    private readonly toasterService: ToasterService
  ) {
    this.router = router;
    this.route = route;
  }

  ngOnInit(): void {
    let userLogged = this.sessionService.getUser();
    this.username = userLogged.name ?? '';
  }

  toogleNavMenu(sidenav: MatSidenav) {
    sidenav.toggle();
  }

  showInfo(navItem: any) {
    let user = this.sessionService.getUser();
    if (!isEmpty(user)) {
      if (this.router.url === '/') {
        if (user.role == UserRole.agent) {
          this.router.navigate(['agent', navItem.link], {
            relativeTo: this.route,
          });
        } else if (user.role == UserRole.branch) {
          this.router.navigate(['branch', navItem.link], {
            relativeTo: this.route,
          });
        }
      }
    }
  }

  selectItem(item: any) {
    this.menu.forEach(elem => {
      elem.selected = false;
    });
    item.selected = true;
    this.router.navigate([item.link]);
  }

  logout() {
    this.sessionService.logout().subscribe(
      user => {
        this.router.navigate(['login']);
      },
      err => {
        console.error(`[DSS layout:logout] ${err.message}`);
      }
    );
  }


  handleError(error: Error) {
    this.toasterService.toast(ToastStatus.error, 'Try again later.');
    console.error('[DSS Layout:Impersonate] ', error);
  }


  logoutImpersonate() {
    this.sessionService.impersonateLogout().subscribe({
      next: _ => {
        console.debug(`[DSS Layout:Impersonate] Loging back to admin`);
        this.toasterService.toast(
          ToastStatus.success,
          'Impersonate session ended.'
        );
        this.router.navigate(['admin', 'signs']);
      },
      error: error => this.handleError(error),
    });
  }


  toggleChatBadgeVisibility() {
    this.chatBadgeisHidden = !this.chatBadgeisHidden;
  }

  toggleNotificationBadgeVisibility() {
    this.notificationBadgeisHidden = !this.notificationBadgeisHidden;
  }
}
