import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { assign } from 'lodash-es';
import { User } from '../core/user';
import { CompanyInfo } from '../core/company-info';

export enum UserRole {
  administrator = 'admin',
  agent = 'agent',
  branch = 'branch',
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private user: User | null = null;
  private impersonate = false;
  private ignore401 = false;

  constructor(private router: Router, private http: HttpClient) {}

  isIgnore401(): boolean {
    return this.ignore401;
  }

  setIgnore401(value: boolean) {
    this.ignore401 = value;
  }
  
  getUser(): User {
    console.debug('[DSS SessionService] getUser called');
    return assign({}, this.user);
  }

  setUser(value: User | null) {
    console.debug('[DSS SessionService] setUser ', value);
    this.user = value;
  }

  isImpersonate(): boolean {
    return this.impersonate;
  }

  fetchCompanyInfo() {
    return this.http
    .post<CompanyInfo>(
      `${environment.backendUrl}/v0/config/company-info`,
      {}
    )
    .pipe(
      map(info => {
        return info
      })
    )
  }

  login(username: string | null, password: string | null) {
    return this.http
      .post<User>(
        `${environment.backendUrl}/v0/auth/login`,
        {
          username,
          password,
        },
        {
          headers: {
            'X-Client-Loading': 'login',
          },
        }
      )
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          this.setUser(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    // localStorage.removeItem('user');
    //this.userSubject.next(undefined);
    return this.http
      .post<User>(
        `${environment.backendUrl}/v0/auth/logout`,
        {},
        { headers: { 'X-Client-Loading': 'logout' } }
      )
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('user', JSON.stringify({}));
          // this.userSubject.next(undefined);
          this.setUser(null);
          return user;
        })
      );
  }

  // reset-password/first-login
  resetPassowrd(password: string | null) {
    // remove user from local storage and set current user to null
    // localStorage.removeItem('user');
    //this.userSubject.next(undefined);
    if (!password) {
      throw new Error('Password is required');
    }

    return this.http
      .post<User>(
        `${environment.backendUrl}/v0/auth/reset-password/first-login`,
        { password }
      )
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          this.setUser(user);
          return user;
        })
      );
  }

  // register(user: User) {
  //   return this.http.post(`${environment.backendUrl}/users/register`, user);
  // }

  getMe() {
    return this.http.get<User>(`${environment.backendUrl}/v0/auth/jwt/me`);
  }

  getById(id: string) {
    return this.http.get<User>(`${environment.backendUrl}/v0/user/${id}`);
  }

  refresh() {
    return this.http.get<User>(`${environment.backendUrl}/v0/auth/jwt/refresh`);
  }

  // update(id: string, params: any) {
  //   return this.http.put(`${environment.backendUrl}/users/${id}`, params).pipe(
  //     map((x) => {
  //       // update stored user if the logged in user updated their own record
  //       if (id == this.userValue.id) {
  //         // update local storage
  //         const user = { ...this.userValue, ...params };
  //         localStorage.setItem('user', JSON.stringify(user));

  //         // publish updated user to subscribers
  //         this.userSubject.next(user);
  //       }
  //       return x;
  //     })
  //   );
  // }

  // delete(id: string) {
  //   return this.http.delete(`${environment.backendUrl}/users/${id}`).pipe(
  //     map((x) => {
  //       // auto logout if the logged in user deleted their own record
  //       if (id == this.userValue.id) {
  //         this.logout();
  //       }
  //       return x;
  //     })
  //   );
  // }

  impersonateOriginalMe() {
    this.setIgnore401(true);
    return this.http
    .get<User>(`${environment.backendUrl}/v0/auth/impersonate/me`, { headers: { 'X-Client-Loading': 'impersonate' } })
    .pipe(
      map(user => {
        this.impersonate = true;
        return user;
      }),
      catchError(err => {
        this.impersonate = false;
        console.error(`[DSS SessionService] impersonateOriginalMe error: ${err.message}`)
        return of(null)
      })
    );
  }

  impersonateLogin(id: string) {
    return this.http
      .post<User>(`${environment.backendUrl}/v0/auth/impersonate/login/${id}`, {},   { headers: { 'X-Client-Loading': 'logout' } })
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          this.setUser(user);
          return user;
        })
      );
  }

  impersonateLogout() {
    return this.http
      .post<User>(`${environment.backendUrl}/v0/auth/impersonate/logout`, {},   { headers: { 'X-Client-Loading': 'logout' } })
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          this.setUser(user);
          return user;
        })
      );
  }
}
