<form [formGroup]="form" autocomplete="off">
  <ng-container>
    <div style="display: flex; flex-direction: row">
      <mat-form-field appearance="fill">
        <mat-label>Postcode</mat-label>
        <input matInput placeholder="Ex. SW1W 0NY" value=" " [formControl]="postalCodeControl">
      </mat-form-field>
    </div>
    <div>
      <div class="sign-table" *ngIf="true">
        <table
          class="mat-elevation-z8"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="announceSortChange($event)"
          multiTemplateDataRows>
          <!-- Postal Code Column -->
          <ng-container matColumnDef="postalCode">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by Postal Code">
              <span class="text-bold">Postcode</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ element.postalCode }}
            </td>
          </ng-container>

          <!-- County Column -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">City</span>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.city }}</td>
          </ng-container>

          <!-- House Number Column -->
          <ng-container matColumnDef="houseNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">House No</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.houseNumber }}
            </td>
          </ng-container>

          <!-- Street Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Street</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.address }}
            </td>
          </ng-container>

          <!-- numBoards Column -->
          <ng-container matColumnDef="numBoards">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">No Boards</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ element.numBoards }}
            </td>
          </ng-container>

          <!-- action Column -->
          <ng-container matColumnDef="lastAction.actionRequested">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Last Action</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              <mat-chip style="transform: scale(0.8)" color="normal" selected>
                {{ prettyActionName(element.lastAction?.actionRequested) }}
              </mat-chip>
            </td>
          </ng-container>

          <!-- toRead Column -->
          <ng-container matColumnDef="toRead.description">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">To Read</span>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap">
              {{ element.lastAction?.toRead?.description }}
            </td>
          </ng-container>

          <!-- lastAction.receivedAt Column -->
          <ng-container matColumnDef="lastAction.receivedAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Received At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction?.receivedAt | dateGBFormatter }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="lastActionStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Status</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list>
                <span>
                  <mat-chip
                    [color]="
                      element.lastActionStatus === 'completed'
                        ? 'primary'
                        : element.lastActionStatus === 'scheduled'
                        ? 'accent'
                        : element.lastActionStatus === 'rejected'
                        ? 'warn'
                        : 'blank'
                    "
                    selected
                    style="transform: scale(0.9)">
                    {{ element.lastActionStatus }}
                  </mat-chip>
                </span>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- Change Slip Column -->
          <ng-container matColumnDef="action_change_slip">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Change Slip</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf="element.lastActionStatus === 'completed'"
                mat-mini-fab
                [disabled]="false"
                color="accent"
                (click)="handleChangeWorkType(element, 'change')"
                style="transform: scale(0.75)">
                <mat-icon>note_alt</mat-icon>
              </button>
            </td>
          </ng-container>

          <!--Collect Column -->
          <ng-container matColumnDef="action_collect">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Collect</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.lastActionStatus === 'completed'">
                <div *ngIf="!element.changingCollect">
                  <button
                    mat-mini-fab
                    [disabled]="false"
                    color="accent"
                    (click)="element.changingCollect = true"
                    style="transform: scale(0.75)">
                    <mat-icon>local_shipping</mat-icon>
                  </button>
                </div>
                <div
                  *ngIf="element.changingCollect"
                  style="display: flex; flex-direction: row">
                  <button
                    *ngIf="element.lastActionStatus !== 'pending'"
                    mat-mini-fab
                    [disabled]="false"
                    color="warn"
                    (click)="element.changingCollect = false"
                    style="transform: scale(0.6)">
                    <mat-icon>close</mat-icon>
                  </button>
                  <button
                    *ngIf="element.lastActionStatus !== 'pending'"
                    mat-mini-fab
                    [disabled]="false"
                    color="primary"
                    (click)="handleChangeWorkType(element, 'collect')"
                    style="transform: scale(0.6)">
                    <mat-icon>done</mat-icon>
                  </button>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <!-- Re-Erect Column -->
          <ng-container matColumnDef="action_re_erect">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Re-erect</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf="element.lastActionStatus === 'completed'"
                mat-mini-fab
                [disabled]="false"
                color="accent"
                (click)="handleChangeWorkType(element, 'reerect')"
                style="transform: scale(0.75)">
                <mat-icon>inventory</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- workType Column -->
          <ng-container matColumnDef="workType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Last Action</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list>
                <span>
                  <mat-chip
                    *ngIf="element.lastAction"
                    [color]="element.workType === 'new' ? 'primary' : 'accent'"
                    selected>
                    {{ element.lastAction?.defaultStaff }}
                  </mat-chip>
                </span>
              </mat-chip-list>
            </td>
          </ng-container>

          <!-- createdBy Branch Column -->
          <ng-container matColumnDef="branch.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Branch</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="white-space: nowrap"
              [matTooltip]="getUserName(element.branch)">
              {{
                getUserName(element.branch).length > 8
                  ? (getUserName(element.branch) | slice : 0 : 8) + '...'
                  : getUserName(element.branch)
              }}
              <!-- {{ getUserName(element.branch) }} -->
            </td>
          </ng-container>

          <!-- createdBy Agent Column -->
          <ng-container matColumnDef="agent.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="text-bold">Agent</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="white-space: nowrap"
              [matTooltip]="getUserName(element.agent)">
              {{
                getUserName(element.agent).length > 8
                  ? (getUserName(element.agent) | slice : 0 : 8) + '...'
                  : getUserName(element.agent)
              }}
            </td>
          </ng-container>

          <!-- completed At Column -->
          <ng-container matColumnDef="lastAction.completedAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Completed At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction?.completedAt | dateGBFormatter }}
              <mat-form-field appearance="fill" style="display: none">
                <mat-label>Date</mat-label>
                <input
                  matInput
                  disabled
                  (value)="(element.lastAction?.completedAt)" />
                <mat-hint>MM/DD/YYYY</mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- scheduleAt Column -->
          <ng-container matColumnDef="lastAction.scheduledAt">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Scheduled At</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastAction?.scheduledAt | dateGBFormatter }}
            </td>
          </ng-container>

          <!-- Note Column -->
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Agent Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                style="transform: 'scale(0.6)'"
                mat-mini-fab
                disabled="true"
                color="accent"
                [disabled]="!element.lastAction?.notes"
                [matTooltip]="element.lastAction?.notes"
                matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Admin Note Column -->
          <ng-container matColumnDef="adminNotes">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Admin Notes</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                style="transform: 'scale(0.6)'"
                mat-mini-fab
                disabled="true"
                color="accent"
                [disabled]="!element.lastAction?.adminNotes"
                [matTooltip]="element.lastAction?.adminNotes"
                matTooltipPosition="before">
                <mat-icon>notes</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Edit</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-mini-fab
                color="accent"
                disabled="false"
                (click)="handleEdit(element.id, element.lastAction?.status)"
                *ngIf="element.lastAction?.status === 'pending'"
                style="transform: scale(0.75)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-bold">Cancel</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-mini-fab
                color="warn"
                disabled="false"
                (click)="handleDelete(element.id, element.lastAction?.status)"
                *ngIf="element.lastAction?.status === 'pending'"
                style="transform: scale(0.75)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            matRipple
            *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="table-paginator-wrapper">
          <mat-paginator
            #paginator
            class="table-paginator"
            (page)="handlePageEvent($event)"
            [length]="length"
            [pageSize]="pageSize"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndex"
            aria-label="Select page">
          </mat-paginator>
        </div>
        
      </div>
    </div>
  </ng-container>
</form>
