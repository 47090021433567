<form [formGroup]="form" autocomplete="off">
    <ng-container>
      
     
  
        <div class="sign-table" *ngIf="true">
            <table class="mat-elevation-z8" mat-table [dataSource]="dataSource" matSort
            multiTemplateDataRows>

            <!-- role Column -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span class="text-bold">Role</span>
                </th>
                <td mat-cell *matCellDef="let element">
                {{ element.role }}
                </td>
            </ng-container>

            <!-- username Column -->
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Username</span>
                </th>
                <td mat-cell *matCellDef="let element">
                {{ element.username }}
                </td>
            </ng-container>


            <!-- status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Status</span> </th>
                <td mat-cell *matCellDef="let element">
                <mat-chip-list *ngIf="element.id !== editUserId">
                    <span>
                    <mat-chip [color]="
                                element.status === 'Active'
                                    ? 'primary'
                                    : element.status === 'On Hold'
                                    ? 'accent'
                                    : element.status === 'Inactive'
                                    ? 'warn'
                                    : 'blank'
                                " selected style="transform: scale(0.9)">
                        {{ element.status }}
                    </mat-chip>
                    </span>
                </mat-chip-list>

                <mat-form-field *ngIf="element.id === editUserId">
                    <mat-select [(value)]="element.status">
                        <mat-option value="Active">Active</mat-option>
                        <mat-option value="Inactive">Inactive</mat-option>
                        <mat-option value="On Hold">On Hold</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
            </ng-container>

             <!-- paswword Column -->
             <ng-container matColumnDef="password">
                <th mat-header-cell *matHeaderCellDef> <span class="text-bold">Password</span> </th>
                <td mat-cell *matCellDef="let element">
                    <input matInput placeholder="*******" readonly type="password" *ngIf="editUserId !== element.id">
                    <input matInput placeholder="Password" type="text" *ngIf="editUserId === element.id" [value]="element.password" (change)="inputPassword(element, $event)">
                </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Edit</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab color="accent" disabled="false"
                (click)="handleEdit(element.id)" *ngIf="
                    element.role === 'branch' && element.id !== editUserId
                " style="transform: scale(0.75)">
                <mat-icon>edit</mat-icon>
                </button>

                <button mat-mini-fab color="primary" disabled="false"
                (click)="handleSave(element)" *ngIf="
                    element.role === 'branch' && element.id === editUserId
                " style="transform: scale(0.75)">
                <mat-icon>save</mat-icon>
                </button>
            </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="toggleRow(element)"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsFooter"></tr>

            </table>
            <div class="table-paginator-wrapper">
                <mat-paginator
                  #paginator
                  class="table-paginator"
                  (page)="handlePageEvent($event)"
                  [length]="length"
                  [pageSize]="pageSize"
                  [showFirstLastButtons]="showFirstLastButtons"
                  [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                  [hidePageSize]="hidePageSize"
                  [pageIndex]="pageIndex"
                  aria-label="Select page">
                </mat-paginator>
              </div>

        </div>
   
    </ng-container>
  </form>
  