<div>
  <mat-form-field appearance="fill">
    <mat-label>Invoice Type</mat-label>
    <mat-select
      [(value)]="invoiceTypeSelected"
      (valueChange)="hangleInvoiceTypeChanged($event)">
      <mat-option value="oneoff">One-Off</mat-option>
      <mat-option value="recurring" disabled="disabled">Recurring</mat-option>
      <mat-option value="monthly" disabled="disabled">Monthly</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<mat-vertical-stepper [linear]="false" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Invoice Recipient</ng-template>

      <mat-label id="radio-group-label"
        >What type of client is the invoice for?</mat-label
      >
      <mat-radio-group
        aria-labelledby="radio-group-label"
        class="example-radio-group"
        formControlName="selectedRecipient"
        [(ngModel)]="seletectedRecipient"
        (valueChange)="handleInvoiceRecipientTypeChanged($event)">
        <mat-radio-button
          class="example-radio-button"
          *ngFor="let recipient of recipients"
          [value]="recipient"
          (change)="handleInvoiceRecipientTypeChanged($event)">
          {{ recipient }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="fill" (disabled)="(true)">
        <mat-label>Invoice Recipient</mat-label>
        <mat-select
          [disabled]="clients.length === 0"
          (valueChange)="handleClientChange($event)">
          <mat-option *ngFor="let client of clients" [value]="client">
            {{ client.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Recipient Details</ng-template>
      <div class="second-step-form">
        <!-- <mat-form-field appearance="fill">
          <mat-label>Branch Id</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 13 Rodney Crescent" required>
        </mat-form-field> -->
        <mat-form-field appearance="fill">
          <mat-label>Branch Name</mat-label>
          <input
            matInput
            formControlName="name"
            [value]="selectedClient?.name"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Branch Address</mat-label>
          <input
            matInput
            formControlName="address"
            [value]="selectedClient?.address"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Branch Telephone</mat-label>
          <input
            matInput
            formControlName="phone"
            [value]="selectedClient?.phone"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Branch Email</mat-label>
          <input
            matInput
            formControlName="email"
            [value]="selectedClient?.email"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Other Info</mat-label>
          <input matInput formControlName="otherInfo" />
        </mat-form-field>
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Invoice Details</ng-template>
    <mat-list>
      <mat-list-item>
        <div>
          <!-- <form [formGroup]="secondFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Quantity</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Price (£)</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Net</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>V.A.T. (%)</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Gross (£)</mat-label>
              <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                     required>
            </mat-form-field>
          </form> -->
        </div>
      </mat-list-item>
    </mat-list>
    <div>
      <table mat-table [dataSource]="dataSource">
        <ng-container
          [matColumnDef]="col.key"
          *ngFor="let col of columnsSchema">
          <th mat-header-cell *matHeaderCellDef>
            {{ col.label }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
              <div class="btn-edit" *ngSwitchCase="'isEdit'">
                <button
                  mat-button
                  color="accent"
                  (click)="element.isEdit = !element.isEdit">
                  Edit
                </button>
                <button
                  mat-button
                  color="warn"
                  class="button-remove"
                  (click)="removeRow(element.id)">
                  Delete
                </button>
              </div>
              <span *ngSwitchDefault>
                {{ element[col.key] }}
              </span>
            </div>
            <div [ngSwitch]="col.type" *ngIf="element.isEdit">
              <div class="btn-edit" *ngSwitchCase="'isEdit'">
                <button mat-button (click)="element.isEdit = !element.isEdit">
                  Done
                </button>
              </div>
              <mat-form-field *ngSwitchCase="'date'" appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="element[col.key]" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field *ngSwitchDefault>
                <input
                  [type]="col.type"
                  matInput
                  [(ngModel)]="element[col.key]" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <button
        mat-raised-button
        color="primary"
        (click)="addRow()"
        style="margin: 20px 0px">
        Add Row
      </button>
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
      <button mat-button (click)="generateInvoice()">Generate Invoice</button>
    </div>
  </mat-step>
</mat-vertical-stepper>
