import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { each, hasIn, orderBy, get } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { MatDialog} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { SignActionsTableConfirmCancelDialogComponent } from '../sign-actions-table-confirm-cancel-dialog/sign-actions-table-confirm-cancel-dialog.component';

@Component({
  selector: 'app-sign-actions-table',
  templateUrl: './sign-actions-table.component.html',
  styleUrls: ['./sign-actions-table.component.scss'],
})
export class SignActionsTableComponent implements OnInit, OnDestroy {
  @Input() usingGlobalFilter = '';

  form: FormGroup = new FormGroup({});

  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
  });

  displayedColumns: string[] = [];
  // dataSource: MatTableDataSource<Sign> = new MatTableDataSource(undefined);
  dataSource: Sign[] = [];

  updateSignSubscription?: Subscription;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  sort: { active: string; direction: string } = {
    active: 'createdAt',
    direction: '',
  };

  postalCodeControl = new FormControl('');
  filterPostalCode: string = '';

  constructor(
    private signService: SignService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  openDialog(
    signId: string,
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(SignActionsTableConfirmCancelDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        signId
      },
    });
  }

  ngOnInit(): void {
    this.postalCodeControl.valueChanges.subscribe(value => {
      this.filterPostalCode = value?.toUpperCase().trim() ?? '';
      this.pageIndex = 0;
      this.fetchItems(this.pageSize, this.pageIndex);
    });

    // this.filterStatus = this.usingGlobalFilter
    this.displayedColumns = [
      'houseNumber',
      'address',
      'postalCode',
      'city',
      'toRead.description',
      'lastAction.actionRequested',
      'lastActionStatus',
      /*       'branch.name', */
      'lastAction.receivedAt',
      'lastAction.scheduledAt',
      'lastAction.completedAt',
      'action_re_erect',
      'action_change_slip',
      'action_collect',
      'note',
      'adminNotes',
      'delete',
    ];
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  ngOnDestroy(): void {
    this.updateSignSubscription?.unsubscribe();
  }

  fetchItems(limit: number, page: number): void {
    let lastActionStatus = null;
    let lastActionStatus_NOT = null;
    if (this.usingGlobalFilter == 'completed') {
      lastActionStatus = BoardStatus.completed;
    } else {
      lastActionStatus_NOT = BoardStatus.completed;
    }

    let order =
      this.sort.direction === ''
        ? []
        : [`${this.sort.active} ${this.sort.direction.toUpperCase()}`];
    this.signService
      .fetchUserAllBoard({
        limit,
        page,
        orderBy: order,
        lastActionStatus,
        lastActionStatus_NOT,
        isActive: true,
        postalCode: this.filterPostalCode
      })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        const items = value.items;
        each(items, item => {
          // item.createdAt = this.enGBFormatter.format(cAt);
          item.readSign = this.prettyReadSign(item.toRead);

          item.actions = orderBy(item.actions, ['receivedAt'], ['desc']);
          item.lastAction = item.actions[0];
          if (item.lastAction) {
            item.lastAction.notes = get(item, 'lastAction.notes', '').trim();
          }

          // if (item.schedule) {
          //   let sAt: Date = new Date(item.schedule.scheduleAt ?? '');
          //   item.scheduleAt = this.enGBFormatter.format(sAt);

          //   item.scheduleTo = item.schedule.worker;
          // }

          if (item.actionRequested) {
            item.action = this.signService.getBoardActionPrettyName(
              item.actionRequested
            );
          }
        });
        this.dataSource = items;
      });
  }

  prettyReadSign(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  statusColor(status: BoardStatus): string {
    switch (status) {
      case BoardStatus.scheduled:
        return 'accent';
      case BoardStatus.pending:
        return 'warn';
      case BoardStatus.completed:
        return 'primary';
      case BoardStatus.rejected:
        return 'warn';
    }
  }

  // export enum Action {
  //   erect = 'E',
  //   reerect = 'R',
  //   change = 'C',
  //   collect = 'COL',
  //   stray = 'STR'
  // }
  prettyActionName(action: Action): string {
    switch (action) {
      case Action.erect:
        return 'Erect';
      case Action.reerect:
        return 'Re-erect';
      case Action.change:
        return 'Change';
      case Action.collect:
        return 'Collect';
      case Action.stray:
        return 'Stray';
      default:
        return 'ERROR';
    }
  }

  getUserName(element: any) {
    return get(element, ['name'], '');
  }

  announceSortChange(sortState: any) {
    this.sort = sortState;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleStatusChanged() {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleEdit(signId: string, signStatus: string) {
    if (signStatus == 'pending') {
      this.router.navigate(['branch', 'request', 'sign', signId]);
    }
  }

  handleDelete(signId: string, signStatus: string) {
    if (signStatus == 'pending') {
      this.openDialog(signId, '0ms', '0ms');
    }
  }

  handleEdit2() {
    // if (signStatus == 'pending') {
    //   this.router.navigate(['branch', 'sign', signId]);
    // }
  }

  handleShowNote(sign: Sign) {
    sign.hasNotes = true;
  }

  handleNew() {
    this.router.navigate(['branch', 'sign']);
  }

  handleChangeWorkType(element: Sign, newWorkType: string) {
    // let handleError = (error: Error) => {
    //   // this.isLoading = false;
    //   this.toasterService.toast(ToastStatus.error, 'Try again later.');
    //   console.error('Error!: ', error);
    // };

    // this.updateSignSubscription = this.signService
    //   .updateSign(
    //     assign(omit(element, ['createdBy']), { workType: newWorkType })
    //   )
    //   .subscribe({
    //     next: sign => {
    //       // this.isLoading = false;
    //       // console.log('Created! SIGN: ', sign);
    //       this.toasterService.toast(ToastStatus.success, 'Edit sucessful');
    //       this.router.navigate(['branch', 'request', 'change-table']);
    //       // this.fetchSigns()
    //       this.fetchItems(this.pageSize, this.pageIndex);
    //     },
    //     error: error => handleError(error),
    //   });
    this.router.navigate(['branch', 'request', 'sign', element.id], {
      queryParams: { action: newWorkType },
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map(str => +str);
    }
  }
}
