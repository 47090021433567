<div class="chartjs-block">
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [plugins]="lineChartPlugins">
  </canvas>
</div>
