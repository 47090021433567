import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { has, isObject } from 'lodash-es';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { User } from 'src/app/core/user';
import { validateUUID } from 'src/app/core/utils';
import { SessionService } from '../session.service';
import { CompanyInfo } from 'src/app/core/company-info';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  companyInfo: CompanyInfo = {
    fullName: '',
    shortName: '',
    mobile: '',
    email: '',
  }

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  onSubmit() {
    if (this.loginForm.valid) {
      let loginData = this.loginForm.value;
      this.sessionService
        .login(loginData.username || null, loginData.password || null)
        .subscribe({
          next: user => {
            this.handleUser(user);
          },
          error: error => {
            if(error.status == 403) { // Forbidden
              this.toasterService.toast(
                ToastStatus.warning,
                'Your account is currently disabled.',
                'Login failed'
              );
            } else {
              this.toasterService.toast(
                ToastStatus.warning,
                'Please check your username and password.',
                'Login failed'
              );
            }
          },
        });
    }
  }

  handleUser(user: Partial<User>) {
    if (isObject(user) && has(user, 'id') && validateUUID(user.id ?? '')) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.sessionService.fetchCompanyInfo().subscribe(
      {
        next: info => {
          console.log('LOGIN PAGE COMPANY INFO: ', info);
          this.companyInfo = info;
        },
        error: error => {
          console.error('LOGIN PAGE COMPANY INFO ERROR', error);
          // this.loginError = error.statusText;
        },
      }
    )
  }
}
