import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collect-stray-sign',
  templateUrl: './collect-stray-sign.component.html',
  styleUrls: ['./collect-stray-sign.component.scss'],
})
export class CollectStraySignComponent {
  constructor() {}
}
