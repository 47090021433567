// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sign-table-nested',
//   templateUrl: './sign-table-nested.component.html',
//   styleUrls: ['./sign-table-nested.component.scss']
// })
// export class SignTableNestedComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { BoardStatus, Sign, SignService } from 'src/app/signs/sign.service';
import { each, get, hasIn, orderBy } from 'lodash-es';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-sign-table-nested',
  templateUrl: './sign-table-nested.component.html',
  styleUrls: ['./sign-table-nested.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SignTableNestedComponent {

  @ViewChild('outerSort', { static: true }) sort: MatSort = new MatSort();
  @ViewChildren('innerSort') innerSort: QueryList<MatSort> = new QueryList<MatSort>();
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<Address>> = new QueryList<MatTable<Address>>();

  // dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  // usersData: User[] = [];
  // columnsToDisplay = ['name', 'email', 'phone'];
  innerDisplayedColumns = ['street', 'zipCode', 'city'];
  expandedElement: User | null = null;

  columnsToDisplay = [
    'houseNumber',
    'address',
    'city',
    'postalCode',
    'lastAction.actionRequested',
    'lastActionStatus',
    'lastAction.receivedAt',
    'lastAction.scheduledAt',
    'lastAction.completedAt',
    'note',
    'lastAction.adminNotes',
    'edit',
    'delete',
  ];
  columnsFooter = ['postalCode'];
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];

  dataSource: MatTableDataSource<Sign> = new MatTableDataSource();


  form: FormGroup = new FormGroup({});

  filterStatus: BoardStatus | string = 'null';
  status: any[] = [
    { id: 'pending', description: 'Pending' },
    { id: 'completed', description: 'Completed' },
    { id: 'scheduled', description: 'Scheduled' },
  ];

  
  filterPostalCode: string | null = null;
  postalCodeControl = new FormControl('');

  constructor(
    private cd: ChangeDetectorRef,
    private signService: SignService,
  ) { }

  ngOnInit() {
    // USERS.forEach(user => {
    //   if (user.addresses && Array.isArray(user.addresses) && user.addresses.length) {
    //     this.usersData = [...this.usersData, { ...user, addresses: new MatTableDataSource(user.addresses) }];
    //   } else {
    //     this.usersData = [...this.usersData, user];
    //   }
    // });
    // this.dataSource = new MatTableDataSource(this.usersData);
    // this.dataSource.sort = this.sort;

    this.fetchItems(this.pageSize, this.pageIndex);

  }

  getElementData(element: any, path: string) {
    return get(element, path)
  }

  handleStatusChanged(value: string) {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  toggleRow(element: User) {
    console.log(element);
    element.addresses && (element.addresses as MatTableDataSource<Address>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Address>).sort = this.innerSort.toArray()[index]);
  }

  // applyFilter(filterValue: string) {
  //   this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Address>).filter = filterValue.trim().toLowerCase());
  // }

  fetchItems(limit: number, page: number): void {
    // let status = this.filterStatus === null ? null : this.filterStatus;

    // if(this.usingCollectSettings) {
    //   status = BoardStatus.completed
    // }
    let order =
      this.sort.direction === ''
        ? []
        : [`${this.sort.active} ${this.sort.direction.toUpperCase()}`];

     let lastActionStatus = this.filterStatus === null ? null : this.filterStatus;
    this.signService
      .fetchUserAllBoard({ limit, page, orderBy: order, status, isActive: true, lastActionStatus, postalCode: this.filterPostalCode })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        const items = value.items;
        each(items, item => {
          let cAt: Date = new Date(item.createdAt ?? '');
          // item.createdAt = this.enGBFormatter.format(cAt);
          item.readSign = this.prettyReadSign(item.toRead);

          item.actions = orderBy(item.actions, ['receivedAt'], ['desc']);
          item.lastAction = item.actions[0];
          if (item.lastAction) {
            item.lastAction.notes = get(item, 'lastAction.notes', '').trim();
            console.log("FUCK ACTION:; ", item.lastAction)
            // item.lastAction.adminNotes = get(item, 'lastAction.adminNotes', '').trim();
          }

          // if (item.schedule) {
          //   let sAt: Date = new Date(item.schedule.scheduleAt ?? '');
          //   item.scheduleAt = this.enGBFormatter.format(sAt);

          //   item.scheduleTo = item.schedule.worker;
          // }

          if (item.actionRequested) {
            item.action = this.signService.getBoardActionPrettyName(
              item.actionRequested
            );
          }
        });
        // this.dataSource = new MatTableDataSource(items);
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.sort = this.sort;
      });
  }

  prettyReadSign(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  statusColor(status: BoardStatus): string {
    switch (status) {
      case BoardStatus.scheduled:
        return 'accent';
      case BoardStatus.pending:
        return 'warn';
      case BoardStatus.completed:
        return 'primary';
      case BoardStatus.rejected:
        return 'warn';
    }
  }

}





export interface User {
  name: string;
  email: string;
  phone: string;
  addresses?: Address[] | MatTableDataSource<Address>;
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
}

export interface UserDataSource {
  name: string;
  email: string;
  phone: string;
  addresses?: MatTableDataSource<Address>;
}

const USERS: User[] = [
  {
    name: "Mason",
    email: "mason@test.com",
    phone: "9864785214",
    addresses: [
      {
        street: "Street 1",
        zipCode: "78542",
        city: "Kansas"
      },
      {
        street: "Street 2",
        zipCode: "78554",
        city: "Texas"
      }
    ]
  },
  {
    name: "Eugene",
    email: "eugene@test.com",
    phone: "8786541234",
  },
  {
    name: "Jason",
    email: "jason@test.com",
    phone: "7856452187",
    addresses: [
      {
        street: "Street 5",
        zipCode: "23547",
        city: "Utah"
      },
      {
        street: "Street 5",
        zipCode: "23547",
        city: "Ohio"
      }
    ]
  }
];