// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sign-table-nested-example',
//   templateUrl: './sign-table-nested-example.component.html',
//   styleUrls: ['./sign-table-nested-example.component.scss']
// })
// export class SignTableNestedExampleComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { BoardStatus, Sign, SignService } from 'src/app/signs/sign.service';
import { each, get, hasIn, orderBy } from 'lodash-es';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-sign-table-nested-example',
  templateUrl: './sign-table-nested-example.component.html',
  styleUrls: ['./sign-table-nested-example.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SignTableNestedExampleComponent {

  @ViewChild('outerSort', { static: true }) sort: MatSort = new MatSort();
  @ViewChildren('innerSort') innerSort: QueryList<MatSort> = new QueryList<MatSort>();
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>> = new QueryList<MatTable<any>>();

  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  usersData: User[] = [];
  columnsToDisplay = ['name', 'email', 'phone'];
  innerDisplayedColumns = ['street', 'zipCode', 'city'];
  expandedElement: User | null = null;

  
  constructor(
    private cd: ChangeDetectorRef,
    private signService: SignService,
  ) { }

  ngOnInit() {
    USERS.forEach(user => {
      if (user.addresses && Array.isArray(user.addresses) && user.addresses.length) {
        this.usersData = [...this.usersData, { ...user, addresses: new MatTableDataSource(user.addresses) }];
      } else {
        this.usersData = [...this.usersData, user];
      }
    });
    this.dataSource = new MatTableDataSource(this.usersData);
    this.dataSource.sort = this.sort;

  }

  toggleRow(element: User) {
    element.addresses && (element.addresses as MatTableDataSource<Address>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Address>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(event: any) {
    console.log(event);
    let filterValue = event.target.value
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Address>).filter = filterValue.trim().toLowerCase());
  }
}

export interface User {
  name: string;
  email: string;
  phone: string;
  addresses?: Address[] | MatTableDataSource<Address>;
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
}

export interface UserDataSource {
  name: string;
  email: string;
  phone: string;
  addresses?: MatTableDataSource<Address>;
}

const USERS: User[] = [
  {
    name: "Mason",
    email: "mason@test.com",
    phone: "9864785214",
    addresses: [
      {
        street: "Street 1",
        zipCode: "BS10 5AD",
        city: "Bristol"
      },
      {
        street: "Street 2",
        zipCode: "BS10 5AD",
        city: "Bristol"
      },
      {
        street: "Street 3",
        zipCode: "B10 0AB",
        city: "Birmingham"
      }
    ]
  },
  {
    name: "Eugene",
    email: "eugene@test.com",
    phone: "8786541234",
  },
  {
    name: "Jason",
    email: "jason@test.com",
    phone: "7856452187",
    addresses: [
      {
        street: "Street 4",
        zipCode: "BR1 1AA",
        city: "Greater London"
      },
      {
        street: "Street 5",
        zipCode: "BR1 1DE",
        city: "Greater London"
      },
      {
        street: "Street 6",
        zipCode: "BR1 1DD",
        city: "Greater London"
      }
    ]
  }
];