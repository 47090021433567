import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { compact, each, get, hasIn, map, values } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { WorkCompletedConfirmUnscheduleDialogComponent } from '../work-completed-confirm-unschedule-dialog/work-completed-confirm-unschedule-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WorkCompletedConfirmRejectedDialogComponentComponent } from '../work-completed-confirm-rejected-dialog-component/work-completed-confirm-rejected-dialog-component.component';

interface SelectValue {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-work-completed-table-admin',
  templateUrl: './work-completed-table-admin.component.html',
  styleUrls: ['./work-completed-table-admin.component.scss'],
})
export class WorkCompletedTableAdminComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
  });

  displayedColumns: string[] = [
    'select',
    'board.houseNumber',
    'board.address',
    'board.city',
    'board.postalCode',
    'numBoards',
    'actionRequested',
    'toRead.description',
    'status',
    // 'action_unschedule',
    'branch.name',
    /*     'agent.name', */
    'ba.scheduleTo',
    'scheduledAt',
    'receivedAt',
    'note',
    'adminNote'
  ];

  updateSignSubscription?: Subscription;

  selection = new SelectionModel<Sign | null>(true, []);

  displayedColumnsFooter: string[] = ['board.houseNumber'];

  @ViewChild('table') table!: MatTable<Sign>;
  // dataSource: MatTableDataSource<Sign> = new MatTableDataSource(undefined);
  dataSource: Sign[] = [];

  userSelectValues: SelectValue[] = [
    {
      value: 'c88ac485-f823-4418-b1dd-e0e66a9af631',
      viewValue: 'Company Test',
    },
  ];

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  sort: { active: string; direction: string } = {
    active: 'createdAt',
    direction: '',
  };

  filterStatus: BoardStatus | string = 'scheduled';

  filterScheduledTo: string = '';
  admins: any[] = [];

  filterActionType: Action | string = '';
  actions: any[] = [
    { id: 'E', description: 'Erect' },
    { id: 'R', description: 'Re-erect' },
    { id: 'C', description: 'Change Slip' },
    { id: 'COL', description: 'Collect' },
    { id: 'STR', description: 'Stray Board' },
  ];

  filterScheduleAt: Action | string = '';
  dates: any[] = []

  datasource: MatTableDataSource<Sign> = new MatTableDataSource();

  selectedBoardActions = [];

  updateNoteSubscription?: Subscription;

  constructor(
    private signService: SignService,
    private router: Router,
    private toasterService: ToasterService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  firstFormGroup = this._formBuilder.group({
    date: new FormControl('', [Validators.required]),
  });

  fetchItems(limit: number, page: number): void {
    let status = this.filterStatus === null ? null : this.filterStatus;
    let scheduleTo =
      this.filterScheduledTo === null ? null : this.filterScheduledTo;
    let actionRequested =
      this.filterActionType === null ? null : this.filterActionType;
    let orderBy =
      this.sort.direction === ''
        ? []
        : [`${this.sort.active} ${this.sort.direction.toUpperCase()}`];
    let scheduleAt = this.filterScheduleAt === null ? null : this.filterScheduleAt;

    this.signService
      .fetchBoardActions({
        limit,
        page,
        orderBy,
        status,
        scheduleTo,
        actionRequested,
        scheduleAt,
      })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        const items = value.items;
        each(items, item => {
          let cAt: Date = new Date(item.createdAt ?? '');
          // item.createdAt = this.enGBFormatter.format(cAt);
          item.readSign = this.prettyReadSign(item.toRead);

          // if (item.schedule) {
          //   let sAt: Date = new Date(item.schedule.scheduleAt ?? '');
          //   item.scheduleAt = this.enGBFormatter.format(sAt);

          //   item.scheduleTo = item.schedule.worker;
          // }

          if (item.actionRequested) {
            item.action = this.signService.getBoardActionPrettyName(
              item.actionRequested
            );
          }
        });
        // this.dataSource = new MatTableDataSource(items);
        this.dataSource = items;
        console.log(this.dataSource);
      });


      this.signService
      .fetchBoardActionScheduleDates({
        orderBy,
        status,
        scheduleTo,
        actionRequested,
      })
      .subscribe(values => {
        console.log(values);
        each(values, dateString => {
          const date = new Date(dateString);

          const day = String(date.getUTCDate()).padStart(2, '0');
          const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns a zero-based index
          const year = date.getUTCFullYear();

          const ukFormattedDate = `${day}/${month}/${year}`;
          this.dates.push(
            {
              id: dateString,
              description: ukFormattedDate
            }
          )
        })
      });
  
    this.signService.fetchAdmins().subscribe(values => {
      console.log(values);
      this.admins = get(values, 'items', []);
      console.log(this.admins);
    });
  }

  ngOnInit(): void {
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  openDialogUnschedule(
    items: string[],
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(WorkCompletedConfirmUnscheduleDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        items
      },
    });
  }

  openDialogReject(
    items: string[],
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(WorkCompletedConfirmRejectedDialogComponentComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        items
      },
    });
  }

  handleUnschedule(event: any) {
    if (event.status == 'scheduled') {
      this.openDialogUnschedule(event.id, '0ms', '0ms');
    }
  }

  prettyReadSign(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  dropTable(index: number, event: CdkDragDrop<Sign[]>) {
    const prevIndex = this.datasource.data.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(
      this.datasource.data || [],
      prevIndex || 0,
      event.currentIndex
    );
    this.table.renderRows();
    console.log('NEW ORDER: ', this.datasource.data);
  }

  statusColor(status: BoardStatus): string {
    switch (status) {
      case BoardStatus.scheduled:
        return 'accent';
      case BoardStatus.pending:
        return 'warn';
      case BoardStatus.completed:
        return 'primary';
      case BoardStatus.rejected:
        return 'warn';
    }
  }

  // export enum Action {
  //   erect = 'E',
  //   reerect = 'R',
  //   change = 'C',
  //   collect = 'COL',
  //   stray = 'STR'
  // }

  prettyActionName(action: Action): string {
    switch (action) {
      case Action.erect:
        return 'Erect';
      case Action.reerect:
        return 'Re-erect';
      case Action.change:
        return 'Change';
      case Action.collect:
        return 'Collect';
      case Action.stray:
        return 'Stray';
      default:
        return 'ERROR';
    }
  }

  announceSortChange(sortState: any) {
    this.sort = sortState;
    this.fetchItems(this.pageSize, this.pageIndex);
  }
  handleStatusChanged(value: string) {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleScheduleAtChanged(value: string) {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleEdit(sign: any) {
    if (sign.status == BoardStatus.scheduled) return;

    if (!sign.scheduleAt || sign.scheduleAt == '') {
      this.toasterService.toast(
        ToastStatus.error,
        'Missing date for Schedule work!'
      );
      return;
    }

    if (!sign.scheduleTo || sign.scheduleTo == '') {
      this.toasterService.toast(
        ToastStatus.error,
        'Missing user to Schedule work!'
      );
      return;
    }

    if (sign.status == 'pending') {
      let updateSign = {
        id: sign.id,
        status: BoardStatus.scheduled,
        scheduleAt: sign.scheduleAt,
        scheduleTo: sign.scheduleTo,
      };

      let handleError = (error: Error) => {
        // this.isLoading = false;
        this.toasterService.toast(ToastStatus.error, 'Try again later.');
        console.error('Error!: ', error);
      };

      sign.status = BoardStatus.scheduled;
      this.signService.scheduleSign(updateSign).subscribe({
        next: sign => {
          // this.isLoading = false;
          this.toasterService.toast(ToastStatus.success, 'Edit sucessful');
          // this.router.navigate(['admin', 'signs']);
        },
        error: error => handleError(error),
      });
    }
  }

  toogleShowNotes(sign: Sign) {
    console.log(sign.notes);
    sign.hasNotes = !sign.hasNotes;
  }

  scheduleToValueChange(value: string, element: any) {
    element.scheduleTo = value;
    console.log(element, value);
    // element.scheduleTo = value;
  }

  scheduleAtValueChange(event: any, element: any) {
    element.scheduleAt = event.value.toISOString();
    // element.scheduleAt = value;
    // console.log(element, value);
    // element.scheduleTo = value;
  }

  handleNew() {
    this.router.navigate(['admin', 'sign']);
  }

  getUserName(element: any) {
    return get(element, ['username'], '');
  }

  getUserName2(element: any) {
    return get(element, ['name'], '');
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map(str => +str);
    }
  }

  handleCompleteWork() {
    let actions = this.selection.selected;
    let date = this.firstFormGroup.value.date;

    if (!actions || actions.length == 0) {
      this.toasterService.toast(
        ToastStatus.error,
        'Please select actions to complete.'
      );
      return;
    }

    if (!date) {
      this.toasterService.toast(ToastStatus.error, 'Please select a date.');
      return;
    }

    let handleError = (error: Error) => {
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
    };

    this.updateSignSubscription = this.signService
      .completeBoardActions({
        actions: map(actions, 'id'),
        completedAt: date,
      })
      .subscribe({
        next: res => {
          this.toasterService.toast(
            ToastStatus.success,
            'Completed work successfully.'
          );
          this.selection.clear();
          this.fetchItems(this.pageSize, this.pageIndex);
          this.router.navigate(['admin', 'signs']);
        },
        error: error => handleError(error),
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  onAdminNotesChange(element: Sign, event: any) {
    console.log(element);
    console.log(event.value);
    element.adminNotes = event.value;

    if (this.updateNoteSubscription) {
      this.updateNoteSubscription.unsubscribe();
    }

    if (element.id == null) return;

    this.updateNoteSubscription = this.signService.updateBoardAction(element.id, { adminNotes: element.adminNotes }).subscribe({
      next: res => {
        this.toasterService.toast(ToastStatus.success, 'Updated notes successfully.');
      },
      error: error => {
        this.toasterService.toast(ToastStatus.error, 'Error while updating notes! Try again later.');
        console.error('Error!: ', error);
      }
    });
  }

  onAgentNumboardsChange(element: Sign, event: any) {
    console.log("FUCK RES:: ", event?.target?.value)
    console.log(element);
    console.log(event.value);
    element.numBoards = event?.target?.value || 1;

    if (this.updateNoteSubscription) {
      this.updateNoteSubscription.unsubscribe();
    }

    if (element.id == null) return;


    this.updateNoteSubscription = this.signService.updateBoardAction(element.id, { numBoards: element.numBoards }).subscribe({
      next: res => {
        this.toasterService.toast(ToastStatus.success, 'Updated n# boards successfully.');
      },
      error: error => {
        this.toasterService.toast(ToastStatus.error, 'Error while updating notes! Try again later.');
        console.error('Error!: ', error);
      }
    });
  }

  onAgentNotesChange(element: Sign, event: any) {
    console.log(element);
    console.log(event.value);
    element.notes = event.value;

    if (this.updateNoteSubscription) {
      this.updateNoteSubscription.unsubscribe();
    }

    if (element.id == null) return;


    this.updateNoteSubscription = this.signService.updateBoardAction(element.id, { notes: element.notes }).subscribe({
      next: res => {
        this.toasterService.toast(ToastStatus.success, 'Updated notes successfully.');
      },
      error: error => {
        this.toasterService.toast(ToastStatus.error, 'Error while updating notes! Try again later.');
        console.error('Error!: ', error);
      }
    });
  }

  handleUnscheduleSelected() {
    const selectedItems = this.selection.selected;
    if (selectedItems.length === 0) {
      this.toasterService.toast(ToastStatus.error, 'No items selected');
      return;
    }

    const items: string[] = compact(map(selectedItems, sign => sign?.id));
    this.openDialogUnschedule(items, '0ms', '0ms');
  
    // Optionally clear selection after unschedule
    this.selection.clear();
  }

  handleRefuseSelected() {
    const selectedItems = this.selection.selected;
    if (selectedItems.length === 0) {
      this.toasterService.toast(ToastStatus.error, 'No items selected');
      return;
    }

    debugger;
    const items: string[] = compact(map(selectedItems, sign => sign?.id));
    this.openDialogReject(items, '0ms', '0ms');
  
    // Optionally clear selection after unschedule
    this.selection.clear();
  }
}
