import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { assign } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { SignService } from 'src/app/signs/sign.service';


@Component({
  selector: 'app-sign-actions-table-confirm-cancel-dialog',
  templateUrl: './sign-actions-table-confirm-cancel-dialog.component.html',
  styleUrls: ['./sign-actions-table-confirm-cancel-dialog.component.scss']
})
export class SignActionsTableConfirmCancelDialogComponent {
  signId: string = '';

  createSignSubscription?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SignActionsTableConfirmCancelDialogComponent>,
    private readonly signService: SignService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  handleCancel(): void {
    this.dialogRef.close();
  }

  handleConfirm(): void {
    let handleError = (error: Error) => {
      // this.isLoading = false;
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
      this.dialogRef.close();
    };

    this.createSignSubscription = this.signService
      .cancelLastSignAction(this.data.signId)
      .subscribe({
        next: sign => {
          // this.isLoading = false;
          console.log('CANCEL! SIGN: ', sign);
          this.toasterService.toast(ToastStatus.success, 'Cancel sucessful');
          this.dialogRef.close();
          this.router.navigate(['branch', 'request', 'change-table']);
          window.location.reload();
        },
        error: error => handleError(error),
      });

    // this.dialogRef.close();
  }
}
