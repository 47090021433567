import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { each, get, hasIn } from 'lodash-es';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { SignTableConfirmDialogComponent } from '../sign-table-confirm-dialog/sign-table-confirm-dialog.component';

interface SelectValue {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-sign-table-admin',
  templateUrl: './sign-table-admin.component.html',
  styleUrls: ['./sign-table-admin.component.scss'],
})
export class SignTableAdminComponent implements OnInit {
  form: FormGroup = new FormGroup({
    receveidAtStart: new FormControl<Date | null>(null),
    receveidAtEnd: new FormControl<Date | null>(null),
    completedAtStart: new FormControl<Date | null>(null),
    completedAtEnd: new FormControl<Date | null>(null),
  });

  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
  });

  displayedColumns: string[] = [
    'board.houseNumber',
    'board.address',
    'board.city',
    'board.postalCode',
    'numBoards',
    'actionRequested',
    'toRead.description',
    'status',
    'branch.name',
    /*     'agent.name', */
    'ba.scheduleTo',
    'receivedAt',
    'scheduledAt',
    'completedAt',
    'note',
    'adminNotes',
    'delete',
  ];

  displayedColumnsFooter: string[] = ['board.houseNumber'];

  // dataSource: MatTableDataSource<Sign> = new MatTableDataSource(undefined);
  dataSource: Sign[] = [];

  userSelectValues: SelectValue[] = [
    {
      value: 'c88ac485-f823-4418-b1dd-e0e66a9af631',
      viewValue: 'Company Test',
    },
  ];

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  sort: { active: string; direction: string } = {
    active: 'createdAt',
    direction: '',
  };

  filterStatus: BoardStatus | string = 'pending';

  filterScheduledTo: string = '';
  admins: any[] = [];

  filterActionType: Action | string = '';
  actions: any[] = [
    { id: 'E', description: 'Erect' },
    { id: 'R', description: 'Re-erect' },
    { id: 'C', description: 'Change Slip' },
    { id: 'COL', description: 'Collect' },
    { id: 'STR', description: 'Stray Board' },
  ];

  constructor(
    private signService: SignService,
    private router: Router,
    private toasterService: ToasterService,
    public dialog: MatDialog
  ) { }

  fetchItems(limit: number, page: number): void {
    let status = this.filterStatus === null ? null : this.filterStatus;
    let scheduleTo =
      this.filterScheduledTo === null ? null : this.filterScheduledTo;
    let actionRequested =
      this.filterActionType === null ? null : this.filterActionType;
    let orderBy =
      this.sort.direction === ''
        ? []
        : [`${this.sort.active} ${this.sort.direction.toUpperCase()}`];
    this.signService
      .fetchBoardActions({
        limit,
        page,
        orderBy,
        status,
        scheduleTo,
        actionRequested,
        isActive: true
      })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        const items = value.items;
        each(items, item => {
          let cAt: Date = new Date(item.createdAt ?? '');
          // item.createdAt = this.enGBFormatter.format(cAt);
          item.readSign = this.prettyReadSign(item.toRead);

          // if (item.schedule) {
          //   let sAt: Date = new Date(item.schedule.scheduleAt ?? '');
          //   item.scheduleAt = this.enGBFormatter.format(sAt);

          //   item.scheduleTo = item.schedule.worker;
          // }

          if (item.actionRequested) {
            item.action = this.signService.getBoardActionPrettyName(
              item.actionRequested
            );
          }
        });
        // this.dataSource = new MatTableDataSource(items);
        this.dataSource = items;
        console.log(this.dataSource);
      });

    this.signService.fetchAdmins().subscribe(values => {
      console.log(values);
      this.admins = get(values, 'items', []);
      console.log(this.admins);
    });
  }

  ngOnInit(): void {
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  prettyReadSign(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  statusColor(status: BoardStatus): string {
    switch (status) {
      case BoardStatus.scheduled:
        return 'accent';
      case BoardStatus.pending:
        return 'warn';
      case BoardStatus.completed:
        return 'primary';
      case BoardStatus.rejected:
        return 'warn';
    }
  }

  // export enum Action {
  //   erect = 'E',
  //   reerect = 'R',
  //   change = 'C',
  //   collect = 'COL',
  //   stray = 'STR'
  // }

  prettyActionName(action: Action): string {
    switch (action) {
      case Action.erect:
        return 'Erect';
      case Action.reerect:
        return 'Re-erect';
      case Action.change:
        return 'Change';
      case Action.collect:
        return 'Collect';
      case Action.stray:
        return 'Stray';
      default:
        return 'ERROR';
    }
  }

  announceSortChange(sortState: any) {
    this.sort = sortState;
    this.fetchItems(this.pageSize, this.pageIndex);
  }
  handleStatusChanged(value: string) {
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  handleDateChanged(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    console.log(dateRangeStart.value);
    console.log(dateRangeEnd.value);
    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);
  }
  handleEdit(sign: any) {
    if (sign.status == BoardStatus.scheduled) return;

    if (!sign.scheduleAt || sign.scheduleAt == '') {
      this.toasterService.toast(
        ToastStatus.error,
        'Missing date for Schedule work!'
      );
      return;
    }

    if (!sign.scheduleTo || sign.scheduleTo == '') {
      this.toasterService.toast(
        ToastStatus.error,
        'Missing user to Schedule work!'
      );
      return;
    }

    if (sign.status == 'pending') {
      let updateSign = {
        id: sign.id,
        status: BoardStatus.scheduled,
        scheduleAt: sign.scheduleAt,
        scheduleTo: sign.scheduleTo,
      };

      let handleError = (error: Error) => {
        // this.isLoading = false;
        this.toasterService.toast(ToastStatus.error, 'Try again later.');
        console.error('Error!: ', error);
      };

      sign.status = BoardStatus.scheduled;
      this.signService.scheduleSign(updateSign).subscribe({
        next: sign => {
          // this.isLoading = false;
          this.toasterService.toast(ToastStatus.success, 'Edit sucessful');
          // this.router.navigate(['admin', 'signs']);
        },
        error: error => handleError(error),
      });
    }
  }

  toogleShowNotes(sign: Sign) {
    console.log(sign.notes);
    sign.hasNotes = !sign.hasNotes;
  }

  scheduleToValueChange(value: string, element: any) {
    element.scheduleTo = value;
    console.log(element, value);
    // element.scheduleTo = value;
  }

  scheduleAtValueChange(event: any, element: any) {
    element.scheduleAt = event.value.toISOString();
    // element.scheduleAt = value;
    // console.log(element, value);
    // element.scheduleTo = value;
  }

  openDialog(
    signId: string,
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    this.dialog.open(SignTableConfirmDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        signId,
      },
    });
  }

  handleDelete(signId: string) {
    this.openDialog(signId, '0ms', '0ms');
  }

  handleNew() {
    this.router.navigate(['admin', 'sign']);
  }

  getUserName(element: any) {
    return get(element, ['name'], '');
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map(str => +str);
    }
  }
}
