<div class="notification-panel">
  <div class="notification-header">Notifications</div>
  <div class="notification-body">
    <div class="notification-item">
      <div class="notification-icon">
        <i class="fas fa-user-friends"></i>
      </div>
      <div class="notification-content">
        <div class="notification-title">Friend Request</div>
        <div class="notification-text">
          John Smith sent you a friend request.
        </div>
      </div>
      <div class="notification-timestamp">1 hr ago</div>
    </div>
    <div class="notification-item">
      <div class="notification-icon">
        <i class="fas fa-heart"></i>
      </div>
      <div class="notification-content">
        <div class="notification-title">Likes</div>
        <div class="notification-text">Jane Doe liked your post.</div>
      </div>
      <div class="notification-timestamp">2 hrs ago</div>
    </div>
  </div>
  <div class="notification-footer">
    <a href="#">Clear All</a>
  </div>
</div>
