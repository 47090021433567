import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { each, sortBy } from 'lodash-es';
import { SessionService } from 'src/app/auth/session.service';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { UserService } from 'src/app/core/user/user.service';

interface Link {
  link: string;
  label: string;
  icon: string;
  children?: Link[];
}

interface SelectValue {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss'],
})
export class LayoutAdminComponent implements OnInit {
  username: string = '';
  chatBadgeisHidden: boolean = false;
  notificationBadgeisHidden: boolean = false;

  impersonateFormGroup = new FormGroup({
    user: new FormControl('', [Validators.required]),
  });
  userSelectValues: SelectValue[] = [
    // {
    //   value: 'c88ac485-f823-4418-b1dd-e0e66a9af631',
    //   viewValue: 'Company Test',
    // },
  ];

  menu = [
    {
      link: 'admin/dashboard',
      label: 'Upload',
      icon: '../../assets/icons/dashboard.svg',
      iconSelected: '../../assets/icons/dashboard-selected.svg',
      selected: true,
    },
    // {
    //   link: 'admin/business',
    //   label: 'Execute',
    //   icon: '../../assets/icons/business.svg',
    //   iconSelected: '../../assets/icons/business-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/freelancers',
    //   label: 'Freelancers',
    //   icon: '../../assets/icons/freelancers.svg',
    //   iconSelected: '../../assets/icons/freelancers-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/companies',
    //   label: 'Companies',
    //   icon: '../../assets/icons/companies.svg',
    //   iconSelected: '../../assets/icons/companies-selected.svg',
    //   selected: false,
    // },
    // {
    //   link: 'admin/payments',
    //   label: 'Payments',
    //   icon: '../../assets/icons/payments.svg',
    //   iconSelected: '../../assets/icons/payments-selected.svg',
    //   selected: false,
    // },
  ];

  options = {
    bottom: 0,
    fixed: true,
    top: 0,
  };

  links: Link[] = [
    // {
    //   link: 'dashboard',
    //   label: 'Dashboard',
    //   icon: 'home_pin',
    // },
    {
      link: 'signs',
      label: 'Boards',
      icon: 'free_cancellation',
    },
    {
      link: 'schedule',
      label: 'Schedule Work',
      icon: 'edit_calendar',
    },
    {
      link: 'todo-list',
      label: 'Schedule List',
      icon: 'event_upcoming',
    },
    {
      link: 'complete-work',
      label: 'Work Complete',
      icon: 'event_available',
    },
    {
      link: 'invoice',
      label: 'Invoice',
      icon: 'receipt_long',
    },
  ];

  // private router: Router;
  // private route: ActivatedRoute;

  constructor(
    private router: Router, // private session: SessionService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private userService: UserService,
    private toasterService: ToasterService
  ) {
    this.router = router;
    this.route = route;
  }

  ngOnInit(): void {
    let userLogged = this.sessionService.getUser();
    this.username = userLogged.name ?? '';

    this.userService.fetchUsers().subscribe(users => {
      each(users.items, item => {
        // debugger
        if (item.role === 'branch') {
          this.userSelectValues.push({
            value: item.id,
            viewValue: item.username,
          });
        }
      });
      this.userSelectValues = sortBy(this.userSelectValues, 'viewValue');
    });
  }

  toogleNavMenu(sidenav: MatSidenav) {
    sidenav.toggle();
  }

  showInfo(navItem: any) {
    this.router.navigate(['admin', navItem.link], { relativeTo: this.route });
  }

  selectItem(item: any) {
    this.menu.forEach(elem => {
      elem.selected = false;
    });
    item.selected = true;
    this.router.navigate([item.link]);
  }

  logout() {
    this.sessionService.logout().subscribe(
      user => {
        this.router.navigate(['login']);
      },
      err => {
        console.error(`[DSS layout:logout] ${err.message}`);
      }
    );
  }

  toggleChatBadgeVisibility() {
    this.chatBadgeisHidden = !this.chatBadgeisHidden;
  }

  toggleNotificationBadgeVisibility() {
    this.notificationBadgeisHidden = !this.notificationBadgeisHidden;
  }

  handleError(error: Error) {
    this.toasterService.toast(ToastStatus.error, 'Try again later.');
    console.error('[DSS Layout:Impersonate] ', error);
  }

  handleUserSelected(value: any) {
    this.sessionService.impersonateLogin(value).subscribe({
      next: result => {
        console.debug(`[DSS Layout:Impersonate] Loging into ${value}`);
        this.toasterService.toast(
          ToastStatus.success,
          'Successfully Impersonated'
        );
        this.router.navigate(['branch', 'homepage'])
        .then(() => {
          window.location.reload() 
        });
      },
      error: error => this.handleError(error),
    });
  }
}
