<div class="create-sign-container">
  <div class="row">
    <div class="col-xs-12">
      <div class="box">
        <mat-card class="card">
          <mat-card-title>
            {{
              isEditing
                ? editAction === 'reerect'
                  ? 'Re-erect Board'
                  : editAction === 'collect'
                  ? 'Collect Board'
                  : editAction === 'change'
                  ? 'Change Board Slip'
                  : 'Edit Board'
                  : usingStraySettings? 'Stray Board'  :'New Board'
            }}</mat-card-title
          >
          <p>Please type in postcode and house number THEN press "find". Amend as needed.</p>
          <!-- <mat-card-subtitle>New Sign</mat-card-subtitle> -->
          <mat-card-content>
            <ng-container>
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <div class="box">
                    <form [formGroup]="currentSignForm" class="form">
                      <!-- <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Company (disabled)</mat-label>
                        <input matInput disabled value="ABC Company" />
                      </mat-form-field> -->

                      <div class="form-line">
                        <mat-form-field class="width-two" appearance="fill">
                          <mat-label>House No</mat-label>
                          <input
                            matInput
                            #house
                            formControlName="houseNumber"
                            placeholder="Ex. 1"
                            (change)="changeSelected()" />
                          <mat-hint align="end"
                            >{{ house.value.length }} / 25</mat-hint
                          >
                        </mat-form-field>

                        <mat-form-field class="width-two" appearance="fill">
                          <mat-label>To Read</mat-label>
                          <mat-select
                            formControlName="toRead"
                            (valueChange)="(toReadValueChange)">
                            <mat-option
                              *ngFor="let read of toReads"
                              [value]="read.id">
                              {{ read.description }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field class="width-two" appearance="fill">
                        <mat-label>Re-Erect</mat-label>
                        <mat-select [disabled]="true"
                          formControlName="workSign"
                          (valueChange)="(workSignValueChange)">
                          <mat-option
                            *ngFor="let work of works"
                            [value]="work.value">
                            {{ work.viewValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field> -->
                      </div>

                      <div class="form-line">
                        <mat-form-field class="full-width" appearance="fill">
                          <mat-label>Address</mat-label>
                          <input
                            matInput
                            #address
                            placeholder="Ex. Milford Ln"
                            formControlName="address"
                            (change)="changeSelected()" />
                          <mat-hint align="end"
                            >{{ address.value.length }} / 36</mat-hint
                          >
                        </mat-form-field>
                      </div>

                      <div class="form-line">
                        <mat-form-field class="full-width" appearance="fill">
                          <mat-label>City</mat-label>
                          <input
                            matInput
                            #city
                            placeholder="Ex. London"
                            formControlName="city"
                            (change)="changeSelected()" />
                          <mat-hint align="end"
                            >{{ city.value.length }} / 58</mat-hint
                          >
                        </mat-form-field>
                      </div>

                      <div class="form-line">
                        <mat-form-field style="width: 25%" appearance="fill">
                          <mat-label>No Boards</mat-label>
                          <input
                            [disabled]="usingStraySettings"
                            matInput
                            #numBoards
                            type="number"
                            formControlName="numBoards"
                            (change)="changeSelected()" />
                          <mat-hint align="end"
                            >{{ numBoards.value }} / 10</mat-hint
                          >
                        </mat-form-field>

                        <mat-form-field style="width: 40%" appearance="fill">
                          <mat-label>Postcode</mat-label>
                          <input
                            matInput
                            #postalCode
                            placeholder="Ex. WC2R 3LL"
                            formControlName="postalCode"
                            (change)="changeSelected(false)" />
                          <mat-hint align="end"
                            >{{ postalCode.value.length }} / 8</mat-hint
                          >
                        </mat-form-field>

                        <ng-container style="width: 8%" appearance="fill">
                          <button
                            mat-raised-button
                            color="primary"
                            style="margin-top: 5px; height: 40px"
                            (click)="buttonFindGeoTag()">
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              ">
                              <mat-icon style="transform: scale(0.75)"
                                >search</mat-icon
                              >
                              Find
                            </div>
                          </button>
                        </ng-container>
                      </div>

                      <div class="form-line">
                        <mat-form-field
                          class="full-width"
                          appearance="fill"
                          style="margin-right: -10px">
                          <mat-label>Notes</mat-label>
                          <textarea
                            matInput
                            #notes
                            placeholder="Additional information"
                            formControlName="notes"
                            (valueChange)="(notesValueChange)"></textarea>
                          <mat-hint align="end"
                            >{{ notes.value.length }} / 256</mat-hint
                          >
                        </mat-form-field>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-sm-12 col-md-8">
                  <div class="box">
                    <div style="display: flex; justify-content: flex-end">
                      <app-google-map></app-google-map>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-card-content>
          <mat-divider inset></mat-divider>
          <mat-card-actions>
            <button mat-raised-button color="primary" (click)="handleCreate()">
              {{ isEditing ? 'SAVE' : 'CREATE' }}
            </button>
          </mat-card-actions>
          <mat-card-footer *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>
</div>
