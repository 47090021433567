<mat-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup" editable="false">
    <form [formGroup]="firstFormGroup" class="form">
      <ng-template matStepLabel>Select Date</ng-template>
      <div class="schedule-filters">
        <!-- <mat-form-field appearance="fill" *ngIf="userSelectValues.length > 0">
          <mat-label>Schedule To</mat-label>
          <mat-select
            formControlName="worker"
            required
            [value]="selectedUser"
            (valueChange)="handleUserSelected($event)">
            <mat-option
              *ngFor="let user of userSelectValues"
              [value]="user.value">
              {{ user.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field appearance="fill" *ngIf="userSelectValues.length > 0">
          <mat-label>Assigned to</mat-label>
          <mat-select
            formControlName="worker"
            required
            [value]="selectedUser"
            (valueChange)="handleUserSelected($event)">
            <mat-option
              *ngFor="let user of userSelectValues"
              [value]="user.value">
              {{ user.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="date-field" appearance="fill">
          <mat-label>Scheduled</mat-label>
          <mat-select formControlName="date" required [value]="selectedDate" (disabled)="true">
            <mat-option
              *ngFor="let date of dateSelectValues"
              [value]="date.value">
              {{ date.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" editable="false" label="Select postcodes">
    <form [formGroup]="secondFormGroup" class="form">
      <ng-container *ngIf="true">
        <div
          class="schedule-table"
          *ngIf="dataSourceBySelectedDate.data.length > 0">
          <!-- <div class="button-container">
            <button mat-raised-button color="primary" (click)="handleNew()">
              {{ "CREATE" }}
            </button>
          </div> -->
          <table
            mat-table
            #table
            [dataSource]="dataSourceBySelectedDate"
            class="mat-elevation-z8"
            cdkDropList
            [cdkDropListData]="dataSourceBySelectedDate.data"
            (cdkDropListDropped)="dropTable1($event)">
            <!-- (cdkDropListDropped)="dropTable(0, $event)"> -->
            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? toggleAllRowsPostalCode() : null"
                  [checked]="
                    selectionPostalCodes.hasValue() && isAllSelectedPostalCode()
                  "
                  [indeterminate]="
                    selectionPostalCodes.hasValue() &&
                    this.selectionPostalCodes.selected.length > 0
                  ">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectionPostalCodes.toggle(row) : null"
                  [checked]="selectionPostalCodes.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>No.</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon cdkDragHandle>reorder</mat-icon>
              </td>
            </ng-container>

            <!-- Postal Code Column -->
            <ng-container matColumnDef="postalCode">
              <th mat-header-cell *matHeaderCellDef>Select Postal Code</th>
              <td mat-cell *matCellDef="let element">
                {{ element.postalCode }}
              </td>
            </ng-container>

            <!-- Table Structure -->
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable1"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsTable1"
              cdkDrag
              [cdkDragData]="row"></tr>
          </table>
        </div>
      </ng-container>

      <div>
        <button mat-raised-button (click)="locationReload()" color="warn">
          Reset
        </button>
        <button
          mat-raised-button
          matStepperNext
          color="primary"
          style="margin-left: 10px">
          Next
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step editable="false">
    <ng-template matStepLabel *ngIf="selectionPostalCodes">Schedule List</ng-template>

    <ng-container>
      <div class="schedule-table">
        <!-- <div class="button-container">
          <button mat-raised-button color="primary" (click)="handleNew()">
            {{ "CREATE" }}
          </button>
        </div> -->
        <ng-container
          *ngFor="
            let dspc of dataSourceByPostalCode.data;
            let indexOfdspc = index
          ">
          <table
            mat-table
            #pdfTable
            [dataSource]="dspc.signs"
            class="mat-elevation-z8"
            cdkDropList
            [cdkDropListData]="dspc.signs"
            (cdkDropListDropped)="dropTable(indexOfdspc, $event, pdfTable)">
            <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>No.</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon cdkDragHandle>reorder</mat-icon>
              </td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef>BT  </th>
              <td mat-cell *matCellDef="let element">
                <input
                  matInput
                  type="number"
                  [value]="element.numBoards"
                  (input)="onBTChange(element, $event)" 
                />
              </td>
            </ng-container>

             <!-- Quantity Property Column -->
             <ng-container matColumnDef="board_numBoards">
              <th mat-header-cell *matHeaderCellDef>BP  </th>
              <td mat-cell *matCellDef="let element">
                {{ element.board?.numBoards }}
              </td>
            </ng-container>

            <!-- Postal Code Column -->
            <ng-container matColumnDef="postalCode">
              <th mat-header-cell *matHeaderCellDef>Postcode</th>
              <td mat-cell *matCellDef="let element">
                {{ element.board?.postalCode }}
              </td>
            </ng-container>

            <!-- City Column -->
            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef>City</th>
              <td mat-cell *matCellDef="let element">
                {{ element.board?.city }}
              </td>
            </ng-container>

            <!-- House Number Column -->
            <ng-container matColumnDef="houseNumber">
              <th mat-header-cell *matHeaderCellDef>House N#</th>
              <td mat-cell *matCellDef="let element">
                {{ element.board?.houseNumber }}
              </td>
            </ng-container>

            <!-- Street Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>Street</th>
              <td mat-cell *matCellDef="let element">
                {{ element.board?.address }}
              </td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="actionRequested">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Action</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap">
                <mat-chip style="transform: scale(0.8)" color="normal" selected>
                  {{ element.actionRequested }}
                </mat-chip>
              </td>
            </ng-container>

            <!-- toRead Column -->
            <ng-container matColumnDef="toRead.description">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">To Read</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap">
                {{ prettyToRead(element.toRead) }}
              </td>
            </ng-container>

            <!-- Branch Column -->
            <ng-container matColumnDef="board.branch.name">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Branch</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="white-space: nowrap"
                [matTooltip]="getUserName(element.board.branch)">
                {{
                  getUserName(element.board.branch).length > 8
                    ? (getUserName(element.board.branch) | slice : 0 : 8) +
                      '...'
                    : getUserName(element.board.branch)
                }}
                <!-- {{ getUserName(element.board.branch) }} -->
              </td>
            </ng-container>

            <!-- lastAction.receivedAt Column -->
            <ng-container matColumnDef="receivedAt">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Received</span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.receivedAt | dateGBFormatter }}
              </td>
            </ng-container>

            <!-- Note Column -->
            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-bold">Notes</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <button
                  style="transform: 'scale(0.6)'"
                  mat-mini-fab
                  disabled="true"
                  color="accent"
                  [disabled]="!element.notes"
                  [matTooltip]="element.notes"
                  matTooltipPosition="before">
                  <mat-icon>notes</mat-icon>
                </button> -->
                {{ element.notes }}
              </td>
            </ng-container>

            <!-- Header row postalCode group -->
            <ng-container matColumnDef="header-row-postalCode-group">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="12">
                {{ dspc.groupBy }}
              </th>
            </ng-container>

            <!-- Table Structure -->
            <tr
              mat-header-row
              *matHeaderRowDef="['header-row-postalCode-group']"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTable3"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsTable3"
              cdkDrag
              [cdkDragData]="row"></tr>
          </table>
        </ng-container>
      </div>
    </ng-container>

    <div>
      <!-- <button mat-button matStepperPrevious>Back</button> -->
      <form [formGroup]="thirdFormGroup" class="form">
        <button mat-raised-button (click)="locationReload()" color="warn">
          Reset
        </button>
        <button
          mat-raised-button
          (click)="downloadAsPDF()"
          color="primary"
          style="margin-left: 10px">
          Print Table
        </button>

        <button
          mat-raised-button
          (click)="saveOrder()"
          color="secondary"
          style="margin-left: 10px">
          Save Order
        </button>
      </form>
     
      <!-- <a mat-button href="https://www.google.com/" target="_blank">Link</a> -->
      <!-- <a mat-raised-button [disabled]="isScheduleDone" (click)="downloadAsPDF()" style="margin-left: 10px"
        >Print Schedule</a
      > -->
    </div>
  </mat-step>
</mat-stepper>
