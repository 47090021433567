<form [formGroup]="form" autocomplete="off">
    <ng-container>
        <div style="display: flex; flex-direction: row">
            <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [(value)]="filterStatus" (valueChange)="handleStatusChanged($event)">
                    <mat-option value="">View All</mat-option>
                    <mat-option *ngFor="let status of status" [value]="status.id">{{
                        status.description
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="margin-left: 20px;">
                <mat-label>Postcode search</mat-label>
                <input matInput placeholder="Ex. SW1W 0NY" value="" [formControl]="postalCodeControl">
            </mat-form-field>
        </div>

        <div>
            <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows
                class="mat-elevation-z8" matSort>
                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail" *ngIf="element.addresses?.data.length"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                                <!-- <mat-form-field>
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field> -->
                                <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.addresses"
                                    matSort>
                                    <ng-container matColumnDef="{{innerColumn}}"
                                        *ngFor="let innerColumn of innerDisplayedColumns">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn}} </th>
                                        <!-- <td mat-cell *matCellDef="let element"> {{element[innerColumn]}} </td> -->
                                          <td mat-cell *matCellDef="let element"> {{ getElementData(element, innerColumn) }} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                                </table>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    [class.example-element-row]="element.addresses?.data.length"
                    [class.example-expanded-row]="expandedElement === element" \(click)="toggleRow(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- <tr mat-footer-row *matFooterRowDef="columnsFooter"></tr> -->
            </table>
        </div>
    </ng-container> 
</form>