import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  each,
  groupBy,
  split,
  find,
  hasIn,
  orderBy,
  toPairs,
  filter,
  includes,
  keys,
  get,
  assign,
  omit,
  map,
} from 'lodash-es';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import {
  Action,
  BoardStatus,
  Sign,
  SignService,
} from 'src/app/signs/sign.service';
import { MatStepper } from '@angular/material/stepper';
import { pluck } from 'rxjs';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import * as _ from 'lodash';
import { UserService } from 'src/app/core/user/user.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { disableDebugTools } from '@angular/platform-browser';
import { DateToStringPipe } from 'src/app/utils/date-to-string.pipe';

// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//   { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//   { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//   { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//   { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//   { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//   { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//   { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//   { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//   { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

interface GroupedSigns {
  groupBy: string;
  signs: any[];
}

interface SelectValue {
  value: string;
  viewValue: string;
}

interface PostalCode {
  position: number;
  postalCode: string;
}

@Component({
  selector: 'app-schedule-todo-list--admin',
  templateUrl: './schedule-todo-list-admin.component.html',
  styleUrls: ['./schedule-todo-list-admin.component.scss'],
})
export class ScheduleTodoListAdminComponent
  implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('pdfTable') tablePrint!: MatTable<Sign>;
  displayedColumnsTable3: string[] = [
    // 'selectSigns',
    'position',
    'toRead.description',
    'houseNumber',
    'address',
    'city',
    'postalCode',
    'actionRequested',
    'quantity',
    'board_numBoards',
    'board.branch.name',
    'receivedAt',
    'note',
  ];

  @ViewChild('table') postalTable!: MatTable<Sign>;

  displayedColumnsTable1: string[] = ['select', 'position', 'postalCode'];
  // dataSource = ELEMENT_DATA;

  // date and time format can have "style" options (i.e. full, long, medium, short)
  enGBFormatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    // timeStyle: 'medium',
  });

  userSelectValues: SelectValue[] = [
    // {
    //   value: 'c88ac485-f823-4418-b1dd-e0e66a9af631',
    //   viewValue: 'Company Test',
    // },
  ];

  dateSelectSubscription?: Subscription;
  dateSelectValues: SelectValue[] = [];

  dataSource?: MatTableDataSource<any> = undefined;

  dataSourceByDate: GroupedSigns[] = [];
  dataSourceBySelectedDate: MatTableDataSource<PostalCode> =
    new MatTableDataSource();

  dataSourceByPostalCode: MatTableDataSource<GroupedSigns> =
    new MatTableDataSource();

  today = new Date();

  currentAvailbableDates: string[] = [];

  @ViewChild('stepper', { static: false }) private stepper!: MatStepper;
  selectedStreamList = [0, 1, 2];

  selectionPostalCodes = new SelectionModel<PostalCode>(
    true,
    [],
    true,
    (otherValue, value) => otherValue.postalCode === value.postalCode
  );

  updateSignSubscription?: Subscription;
  selectedUser: string = '';
  selectedDate: string = '';
  selectedSigns = new SelectionModel<Sign>(
    true,
    [],
    true,
    (otherValue, value) => otherValue.id === value.id
  );
  selectedScheduleAt: Date = new Date();

  isScheduleDone: boolean = false;

  updateNoteSubscription?: Subscription;

  constructor(
    private userService: UserService,
    private signService: SignService,
    private toasterService: ToasterService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router
  ) {
    this.router = router;
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  firstFormGroup = new FormGroup({
    worker: new FormControl('', [Validators.required]),
    date: new FormControl({ value: '', disabled: true }, [Validators.required]),
  });

  secondFormGroup = new FormGroup({
    secondCtrl: new FormControl(['', Validators.required]),
  });

  thirdFormGroup = new FormGroup({
  });

  isLinear = true;

  scheduleAtFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6 && (d || new Date()) > new Date();
  };

  dropTable(index: number, event: CdkDragDrop<Sign[]>, table: MatTable<Sign>) {
    const prevIndex = this.dataSourceByPostalCode.data[index].signs.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(
      this.dataSourceByPostalCode.data[index].signs,
      prevIndex,
      event.currentIndex
    );
    table.renderRows();
    console.log('NEW ORDER: ', this.dataSourceByPostalCode.data[index].signs);
  }

  dropTable1(event: CdkDragDrop<PostalCode[]>) {
    const prevIndex = this.dataSourceBySelectedDate.data.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(
      this.dataSourceBySelectedDate.data,
      prevIndex,
      event.currentIndex
    );
    this.postalTable.renderRows();
    console.log('NEW ORDER2: ', this.dataSourceBySelectedDate.data);
  }

  fetchScheduledBoardActions(): Subscription {
    return this.signService.fetchScheduledBoardActions().subscribe(fetchItems => {
      let currentUser = this.firstFormGroup.get('worker')?.value;
      let currentSigns = _.filter(fetchItems, sign => sign.scheduleTo.id === currentUser);
      this.dataSource = new MatTableDataSource(currentSigns);
      // Create Date Form Values
      let signsGroupedByDate = groupBy(currentSigns, boardAction =>
        this.formatDate(boardAction.scheduledAt)
      );

      this.dateSelectValues = [];
      this.dataSourceByDate = [];

      let sortedByKeys = _(signsGroupedByDate)
        .toPairs()
        .sortBy(0)
        .fromPairs()
        .value();
      _.each(sortedByKeys, (signs, date) => {
        this.dateSelectValues.push({ value: date, viewValue: date });
        this.dataSourceByDate.push({
          groupBy: date,
          signs,
        });
      });
    });
  }

  ngOnInit(): void {

    // this.signService
    //   .fetchSigns({ status: ScheduleStatus.scheduled })
    //   .subscribe(value => {
    //     const items = value.items;
    //     each(items, (item, index) => {
    //       item.position = index;
    //       let cAt: Date = new Date(item.createdAt ?? '');
    //       item.createdAt = this.enGBFormatter.format(cAt);
    //       item.readSign = this.prettyReadSign(item.readSign);

    //       // if (item.scheduleAt) {
    //       //   let sAt: Date = new Date(item.scheduleAt ?? '');
    //       //   item.scheduleAt = this.enGBFormatter.format(sAt);
    //       // }
    //     });
    //     // this.dataSource = new MatTableDataSource(items);
    //     // this.dataSource = items;
    //     this.dataSource = new MatTableDataSource(items);
    //     console.log(this.dataSource);

    //     // Create Date Form Values
    //     let signsGroupedByDate = groupBy(this.dataSource.data, sign =>
    //       this.formatDate(sign.schedule.scheduleAt)
    //     );
    //     each(signsGroupedByDate, (signs, date) => {
    //       this.dateSelectValues.push({ value: date, viewValue: date });
    //       this.dataSourceByDate.push({
    //         groupBy: date,
    //         signs,
    //       });
    //     });
    //     console.log(signsGroupedByDate);
    //   });

    this.userService.fetchAllAdmins().subscribe(users => {
      each(users.items, item => {
        this.userSelectValues.push({
          value: item.id,
          viewValue: item.name,
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.updateSignSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.stepper.selectionChange
      .pipe(pluck('selectedIndex'))
      .subscribe((res: number) => {
        console.log('STEP: ', res);

        if (res === 0) {
          // Schedule Step
          this.dataSourceBySelectedDate = new MatTableDataSource();
          this.dataSourceByPostalCode = new MatTableDataSource();
        }

        if (res === 1) {
          // Postal Codes Step
          let date = this.firstFormGroup.value.date?.toString();
          let worker = this.firstFormGroup.value.worker?.toString();
          console.log('STEP1: SELECTED DATE: ', date);
          console.log('STEP1: SELECTED USER: ', worker);

          let signsToThisDate = find(this.dataSourceByDate, { groupBy: date });
          console.log('STEP1: SIGNS TO THIS DATE: ', signsToThisDate)
          let signsToThisWorker = filter(signsToThisDate?.signs, { scheduleTo: { id: worker } });

          let signsGrouped = groupBy(signsToThisWorker, action => {
            return split(action.board.postalCode, ' ')[0];
          });

          console.log(signsGrouped);
          var position = 0;
          each(signsGrouped, (_, key) => {
            this.dataSourceBySelectedDate.data.push({
              position,
              postalCode: key,
            });
            position += 1;
          });
          console.log('STEP 1: ', this.dataSourceBySelectedDate.data);

          let localStorageData = this.getCurrentStorageData()
          if(localStorageData) {
            
            each(this.dataSourceBySelectedDate.data, data => data.position = 0);

            let selectedValues: PostalCode[] = [];
            let position = 0;

            each(localStorageData, postalSigns => {
              console.log("FONIX ", postalSigns);
              let currentValue = find(this.dataSourceBySelectedDate.data, {postalCode: postalSigns.groupBy});
              if(currentValue) {
                // SELECT
                selectedValues.push(currentValue);

                // REORDER
                const prevIndex = this.dataSourceBySelectedDate.data.findIndex(
                  d => d === currentValue
                );
                moveItemInArray(
                  this.dataSourceBySelectedDate.data,
                  prevIndex,
                  position
                );
                position += 1;
              }
            });

            // SELECT
            this.selectionPostalCodes.select(...selectedValues);
          }

          
        }

        if (res === 2) {
          // Signs Step
          // let date = this.firstFormGroup.value.date?.toString();
          // let worker = this.firstFormGroup.value.worker?.toString();


          // let signsToThisDate = find(this.dataSourceByDate, { groupBy: date });
          // let signsGrouped = groupBy(signsToThisDate?.signs, action => {
          //   return split(action.board.postalCode, ' ')[0];
          // });

          let signsGrouped = groupBy(this.dataSource?.data, action => {
            return split(action.board.postalCode, ' ')[0];
          });

          let selectedPostalCodes: string[] = [];
          each(this.selectionPostalCodes.selected, (value, key) => {
            if (value.postalCode) {
              selectedPostalCodes.push(value.postalCode);
            }
          });

          each(this.dataSourceBySelectedDate.data, postalCode => {
            if (selectedPostalCodes.includes(postalCode.postalCode)) {
              this.dataSourceByPostalCode.data.push({
                groupBy: postalCode.postalCode,
                signs: [],
              });
            }
          });

          each(signsGrouped, (signs, key) => {
            each(this.dataSourceByPostalCode.data, list => {
              if (list.groupBy == key) {
                list.signs = signs;
              }
            });
            // this.dataSourceByPostalCode.data.push({
            //   groupBy: key,
            //   signs,
            // });
          });

          console.log('STEP 2: ', this.dataSourceByPostalCode.data);
        }

        if (res === 3) {
          console.log('STEP 3: ', this.selectedSigns.selected);

          let signsGrouped = groupBy(this.selectedSigns.selected, action => {
            return split(action.board.postalCode, ' ')[0];
          });

          let selectedPostalCodes: string[] = [];
          each(this.selectionPostalCodes.selected, (value, key) => {
            if (value.postalCode) {
              selectedPostalCodes.push(value.postalCode);
            }
          });

          this.dataSourceByPostalCode.data = [];
          each(this.dataSourceBySelectedDate.data, postalCode => {
            if (selectedPostalCodes.includes(postalCode.postalCode)) {
              this.dataSourceByPostalCode.data.push({
                groupBy: postalCode.postalCode,
                signs: [],
              });
            }
          });

          each(signsGrouped, (signs, key) => {
            each(this.dataSourceByPostalCode.data, list => {
              if (list.groupBy == key) {
                list.signs = signs;
              }
            });
            // this.dataSourceByPostalCode.data.push({
            //   groupBy: key,
            //   signs,
            // });
          });

          console.log('STEP 3: ', this.dataSourceByPostalCode.data);
        }
      });
  }

  getUserName(element: any) {
    return `  ${get(element, ['username'], '')}  `;
  }

  prettyToRead(readValue: any): string {
    if (hasIn(readValue, 'description')) {
      return readValue.description || 'ERROR';
    }
    return '';
  }

  scheduleDateSelected(value: string) {
    console.log('VAAALUE: ', value);
  }

  formatDate(date: string | undefined): string {
    if (!date) {
      return '';
    }

    var d = new Date(date),
      month = '' + (d!.getMonth() + 1),
      day = '' + d!.getDate(),
      year = d!.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadAsPDF() {
    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;


    let data = this.dataSourceByPostalCode;

    let docData: any[][][] = [];
    // _.each(data.data, (value, key) => {
    //   docData.push({
    //     postalCode: value.groupBy,
    //     signs: value.signs,
    //   });
    // });

    const datePipe = new DateToStringPipe();
    _.each(data.data, (value, key) => {
      let signsForCurrentPostalCode = _.map(value.signs, (action, key) => {
        return [
          action.board.houseNumber,
          action.board.address,
          action.board.postalCode,
          action.actionRequested + action.toRead.abbreviation,
          action.numBoards,
          action.board.numBoards,
          action.board.branch.username,
          action.notes

        ]
      });
      _.each(signsForCurrentPostalCode, (value, key) => {
        docData.push(value);
      });
    });

    doc.autoTable({
      head: [['House no', 'Address', 'Postcodes', 'Action', 'BT', 'BP', 'Branch', 'Notes']],
      // body: [
      //   ['David', 'david@example.com', 'Sweden'],
      //   ['Castille', 'castille@example.com', 'Norway']
      // ]
      body: docData,
      theme: 'grid',
      styles:
      {
        fontSize: 8,
        halign: 'left',
        valign: 'middle',
        cellPadding: 2,
      }
    });
    doc.save('schedule.pdf')
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedPostalCode() {
    const numSelected = this.selectionPostalCodes.selected.length;
    const numRows = this.dataSourceByPostalCode.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRowsPostalCode() {
    if (!this.isAllSelectedPostalCode()) {
      this.selectionPostalCodes.clear();
      return;
    }

    this.selectionPostalCodes.select(...this.dataSourceBySelectedDate.data);
  }

  async handleUserSelected(value: any) {
    this.dateSelectValues = [];

    if (this.dateSelectSubscription) {
      this.dateSelectSubscription.unsubscribe();
    }

    if (_.isString(value) && !_.isEmpty(value)) {
      this.firstFormGroup.get('date')?.enable();
      this.dateSelectSubscription = this.fetchScheduledBoardActions();
    } else {
      this.firstFormGroup.get('date')?.disable();
    }

  }

  changeNumBoardsSelected(element: Sign, value: any) {
    console.log('ELEMENT: ', element);
    console.log('NUM BOARDS: ', value);
    element.numBoards = parseInt(value);
  }

  locationReload() {
    window.location.reload();
  }

  onBTChange(element: Sign, event: any) {
    console.log("FUCK RES:: ", event?.target?.value)
    console.log(element);
    console.log(event.value);
    element.numBoards = event?.target?.value || 1;

    if (this.updateNoteSubscription) {
      this.updateNoteSubscription.unsubscribe();
    }

    if (element.id == null) return;


    this.updateNoteSubscription = this.signService.updateBoardAction(element.id, { numBoards: element.numBoards }).subscribe({
      next: res => {
        this.toasterService.toast(ToastStatus.success, 'Updated n# boards successfully.');
      },
      error: error => {
        this.toasterService.toast(ToastStatus.error, 'Error while updating notes! Try again later.');
        console.error('Error!: ', error);
      }
    });
  }

  getCurrentStorageKey() {
    let date = this.firstFormGroup.value.date?.toString();
    let worker = this.firstFormGroup.value.worker?.toString();
    console.log('SELECTED DATE: ', date);
    console.log('SELECTED USER: ', worker);
    return `${date}-${worker}`;
  }

  getCurrentStorageData(): any | null{
    
    console.info("FETCHING MEMORY...");

    let data = null;
    const storedDataString = localStorage.getItem(this.getCurrentStorageKey());
    if (storedDataString) {
        data = JSON.parse(storedDataString);
    }

    console.info("SAVED ITEMS: ", data);

    return data;
  }

  saveOrder() {
    // if (this.updateNoteSubscription) {
    //   this.updateNoteSubscription.unsubscribe();
    // }


    // this.updateNoteSubscription = this.signService.updateBoardAction(element.id, { numBoards: element.numBoards }).subscribe({
    //   next: res => {
    //     this.toasterService.toast(ToastStatus.success, 'Updated n# boards successfully.');
    //   },
    //   error: error => {
    //     this.toasterService.toast(ToastStatus.error, 'Error while updating notes! Try again later.');
    //     console.error('Error!: ', error);
    //   }
    // });

    const dataToStore = JSON.stringify(this.dataSourceByPostalCode.data);
    console.log('KEY', this.getCurrentStorageKey());
    localStorage.setItem(this.getCurrentStorageKey(), dataToStore);
    this.toasterService.toast(ToastStatus.success, 'Saved draft successfully.');

    // _.each(this.dataSourceBySelectedDate.data, signs => console.log(signs))
  }
}
