<ng-container>
  <section>
    <mat-card>
      <mat-card-content>
        <div class="form-signin">
          <mat-list>
            <div>
              <h2 class="h2 font-weight-normal">
                {{ companyInfo.fullName }}
              </h2>
              <span class="h3 font-weight-normal">{{ companyInfo.shortName }}</span>
              <br/>
              <a [href]="'tel:' + companyInfo.mobile" class="h3 mb-4 font-weight-normal">{{ companyInfo.mobile }}</a>
              <br/>
              <a [href]="'mailto:' + companyInfo.email" class="h3 mb-4 font-weight-normal">{{ companyInfo.email }}</a>
            </div>
            <mat-divider style="margin-top: 15px; margin-bottom: 15px;"></mat-divider>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <h1 class="h3 font-weight-normal">Login</h1>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Username"
                  formControlName="username"
                  required />
                <mat-error *ngIf="!loginForm.controls.username.valid"
                  >Please enter valid username</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Password"
                  type="password"
                  formControlName="password"
                  required />
                <mat-error *ngIf="!loginForm.controls.password.valid"
                  >Please enter password</mat-error
                > </mat-form-field
              ><br />
              <button type="submit" mat-raised-button color="primary">
                Login
              </button>
            </form>
          </mat-list>
          
        </div>
      </mat-card-content>
    </mat-card>
  </section>
</ng-container>
