import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-homepage-agent',
  templateUrl: './homepage-agent.component.html',
  styleUrls: ['./homepage-agent.component.scss'],
})

export class HomepageAgentComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
  panelOpenState = false;
}

