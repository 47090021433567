import { Component, OnInit } from '@angular/core';
import { User } from '../sign-table-nested/sign-table-nested.component';
import { FormGroup } from '@angular/forms';
import { UserService } from 'src/app/core/user/user.service';
import { each, isEmpty, isString } from 'lodash-es';
import { elementAt } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-user-settings-table',
  templateUrl: './user-settings-table.component.html',
  styleUrls: ['./user-settings-table.component.scss']
})
export class UserSettingsTableComponent implements OnInit {
  form: FormGroup = new FormGroup({});

  displayedColumns: string[] = [];
  displayedColumnsFooter: string[] = [];
  // dataSource: MatTableDataSource<Sign> = new MatTableDataSource(undefined);
  dataSource: User[] = [];

  innerDisplayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  expandedElement: any | null = null;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
 
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;


  editUserId: String|null = null;

  constructor(
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    // this.postalCodeControl.valueChanges.subscribe(value => {
    //   this.filterPostalCode = value?.toUpperCase() ?? null;
    //   this.pageIndex = 0;
    //   this.fetchItems(this.pageSize, this.pageIndex);
    // });

    this.displayedColumns = [
      // 'role',
      'username',
      'status',
      'password',
      // 'email',
      // 'changePassword',
      // 'isActive',
      'edit'
    ];
    this.displayedColumnsFooter = [
      // 'postalCode'
    ];

    this.pageIndex = 0;
    this.fetchItems(this.pageSize, this.pageIndex);

    this.innerDisplayedColumns = [];

  }

  fetchItems(limit: number, page: number): void {
    this.editUserId = null;
    this.pageSize = limit;
    this.pageIndex = page;
    this.userService
      .fetchUsers({ limit: this.pageSize, page: this.pageIndex, role: 'branch' })
      .subscribe(value => {
        const meta = value.meta;
        this.length = meta.totalItems;

        // remove completed strays from board
        const items = value.items;

        this.dataSource = [];
     
        each(items, item => {
          item.password = null;
          this.dataSource.push(item);
        })
      });
  }

  toggleRow(element: any) {
    // console.log("IT WORKS ", element.actions)
    // element.actions && (element.actions as MatTableDataSource<SignAction>) ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    // this.cd.detectChanges();
    // this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<SignAction>).sort = this.innerSort.toArray()[index]);
  }

  handleEdit(userId: string) {
    this.editUserId = userId;
  }

  handleSave(user: any) {
    this.editUserId = null;

    if(user.password != null && user.password != '') {
      // Change Password
      console.log("User password: ", user);
      this.userService.changePassword(user.id, user.password).subscribe(user => {
        console.log("Password changed: ", user);
      });
    }

    this.userService.updateUser(user).subscribe(user => {
      console.log("User updated: ", user);
    });
  }

  inputPassword(element: any, event: any) {
    console.log("PASS", event.target?.value);
    element.password = null;
    if(isString(event.target?.value) && !isEmpty(event.target?.value)) {
      element.password = event.target.value;
    }
    // element.password = password;
  }

  handlePageEvent(e: PageEvent) {
    console.log("Page Event: ", e);
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.fetchItems(this.pageSize, this.pageIndex);
  }

}
