<div>
  <ng-container *ngIf="apiLoaded | async">
    <div class="google-map-container" #map_container>
      <google-map
        (height)="(map_container.offsetHeight)"
        (width)="(map_container.offsetWidth)"
        [zoom]="zoom"
        [center]="center"
        [options]="options"
        (mapClick)="moveMap($event)"
        (mapMousemove)="move($event)">
        <map-marker
          *ngFor="let markerPosition of markerPositions"
          [options]="markerOptions"
          [position]="markerPosition"
          (mapDragend)="moveDragendMaker($event)"></map-marker>
      </google-map>
    </div>
  </ng-container>
</div>
