<div class="agent-dashboard-container">
  <div class="row">
    <div class="col-xs-6">
      <div class="box">
        <mat-card class="">
          <app-doughnut-chart></app-doughnut-chart>
        </mat-card>
      </div>
    </div>
    <div class="col-xs-6">
      <div class="box">
        <mat-card class="margin-left">
          <app-pie-chart> </app-pie-chart>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="box">
        <mat-card class="margin-top">
          <app-line-chart> </app-line-chart>
        </mat-card>
      </div>
    </div>
  </div>
</div>
