
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { each } from 'lodash-es';
import { Subscription } from 'rxjs';
import {
  ToasterService,
  ToastStatus,
} from 'src/app/core/toaster/toaster.service';
import { Sign, SignService } from 'src/app/signs/sign.service';

@Component({
  selector: 'app-work-completed-confirm-unschedule-dialog',
  templateUrl: './work-completed-confirm-unschedule-dialog.component.html',
  styleUrls: ['./work-completed-confirm-unschedule-dialog.component.scss']
})
export class WorkCompletedConfirmUnscheduleDialogComponent implements OnDestroy {
  updateSignSubscription?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<WorkCompletedConfirmUnscheduleDialogComponent>,
    private readonly signService: SignService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnDestroy(): void {
    if (this.updateSignSubscription) {
      this.updateSignSubscription.unsubscribe();
    }
  }

  handleCancel(): void {
    this.dialogRef.close();
  }

  handleConfirm() {
    
    debugger;
    let  boardActionIds: string[] = this.data.items;
    let handleError = (error: Error) => {
      // this.isLoading = false;
      this.toasterService.toast(ToastStatus.error, 'Try again later.');
      console.error('Error!: ', error);
      this.dialogRef.close();
    };

    this.updateSignSubscription = this.signService
      .unscheduleBoardActionList({ boardActionIds })
      .subscribe({
        next: res => {
          // this.toasterService.toast(
          //   ToastStatus.success,
          //   'Schedule successfully created.'
          // );
          this.dialogRef.close();
          // this.router.navigate(['admin', 'complete-work']);
          window.location.reload();
        },
        error: error => handleError(error),
      });
  }

}
