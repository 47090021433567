import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, mergeMap, flatMap, switchAll, switchMap } from 'rxjs/operators';

import { concat, defer, Observable } from 'rxjs';
import { assign } from 'lodash-es';
import { SessionService } from 'src/app/auth/session.service';
import { User } from '../user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient) {}

  fetchUsers(options: any = {}) {
    return this.http
      .get<any>(`${environment.backendUrl}/v0/user`, {
        params: assign(
          {},
          {
            page: 1,
            limit: 100,
            relations: [],
          },
          options
        ),
      })
      .pipe(
        map((users: any) => {
          return users;
        })
      );
  }

  updateUser(user: any) {
    return this.http
      .patch<any>(`${environment.backendUrl}/v0/user/${user.id}`, {
        status: user.status
      })
      .pipe(
        map((user: any) => {
          return user;
        })
      );
  }

  changePassword(userId: string, newPassword: string) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/user/${userId}/admin-change-passowd`,
        { newPassword }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  fetchBranches(options: any = {}) {
    return this.http
      .get<any>(`${environment.backendUrl}/v0/user`, {
        params: assign(
          {},
          {
            page: 1,
            limit: 100,
            relations: [],
            role: 'branch',
          },
          options
        ),
      })
      .pipe(
        map((users: any) => {
          return users;
        })
      );
  }

  fetchAllAdmins(options: any = {}) {
    return this.http
      .get<any>(`${environment.backendUrl}/v0/user`, {
        params: assign(
          {},
          {
            page: 1,
            limit: 100,
            relations: [],
            role: 'admin',
          },
          options
        ),
      })
      .pipe(
        map((users: any) => {
          return users;
        })
      );
  }
}
