<div style="margin-bottom: 30px">
  <h2>Completed Boards</h2>
  <app-sign-actions-table
    usingGlobalFilter="completed"></app-sign-actions-table>
</div>

<div>
  <h2>Pending Boards</h2>
  <app-sign-actions-table usingGlobalFilter=""></app-sign-actions-table>
</div>
