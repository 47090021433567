import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, mergeMap, flatMap, switchAll, switchMap } from 'rxjs/operators';
import { LatLng } from '../components/google-map/geofencing.service';
import { SessionService } from '../auth/session.service';
import { concat, defer } from 'rxjs';
import { assign } from 'lodash-es';

export interface Sign {
  position?: number;
  id?: string | undefined;
  houseNumber: string;
  address: string;
  city: string;
  postalCode: string;
  numBoards: number;
  toRead?: string;
  markerLatLng: LatLng;
  createdAt?: string | undefined | Date;
  updatedAt?: string | undefined;
  deletedAt?: string | undefined | null;
  createdBy?: string;
  status?: BoardStatus;
  schedule?: any | null;
  scheduleAt?: string;
  scheduleTo?: string;
  workType?: string;
  notes?: string;
  hasNotes?: boolean;
  adminNotes?: string;
  board?: any;
  boardType?: string;
  lastAction?: any,
}

export enum BoardStatus {
  scheduled = 'scheduled',
  pending = 'pending',
  rejected = 'rejected',
  completed = 'completed',
}

export enum Action {
  erect = 'E',
  reerect = 'R',
  change = 'C',
  collect = 'COL',
  stray = 'STR',
}

export interface SignsDTO {
  items: any[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SignService {
  constructor(
    private readonly http: HttpClient,
    private readonly sessionService: SessionService
  ) {}

  // createSign(sign: Sign) {
  //   return this.http
  //     .post<any>(`${environment.backendUrl}/v0/board`, {
  //       houseNumber: sign.houseNumber,
  //       city: sign.city,
  //       address: sign.address,
  //       postalCode: sign.postalCode,
  //       toRead: sign.toRead,
  //       markerLatLng: sign.markerLatLng,
  //       numBoards: 1,
  //       isActive: true,
  //       createdBy: sign.createdBy,
  //       notes: sign.notes,
  //       workType: sign.workType,
  //     })
  //     .pipe(
  //       map((sign: Sign) => {
  //         return sign;
  //       })
  //     );
  // }

  createSignErect(sign: Sign) {
    return this.http
      .post<any>(`${environment.backendUrl}/v0/board/erect`, {
        houseNumber: sign.houseNumber,
        city: sign.city,
        address: sign.address,
        postalCode: sign.postalCode,
        toRead: sign.toRead,
        markerLatLng: sign.markerLatLng,
        numBoards: 1,
        branch: sign.createdBy,
        notes: sign.notes
      })
      .pipe(map((result: any) => result));
  }

  createSignStray(sign: Sign) {
    return this.http
      .post<any>(`${environment.backendUrl}/v0/board/stray`, {
        houseNumber: sign.houseNumber,
        city: sign.city,
        address: sign.address,
        postalCode: sign.postalCode,
        toRead: sign.toRead,
        markerLatLng: sign.markerLatLng,
        numBoards: 1,
        branch: sign.createdBy,
        notes: sign.notes
      })
      .pipe(map((result: any) => result));
  }

  scheduleSign(sign: any) {
    return this.http
      .patch<any>(`${environment.backendUrl}/v0/board/${sign.id}`, {
        scheduleAt: sign.scheduleAt,
        scheduleTo: sign.scheduleTo,
        status: sign.status,
      })
      .pipe(
        map((sign: Sign) => {
          return sign;
        })
      );
  }

  updateSign(sign: Sign) {
    return this.http
      .patch<any>(`${environment.backendUrl}/v0/board/${sign.id}`, {
        houseNumber: sign.houseNumber,
        city: sign.city,
        address: sign.address,
        postalCode: sign.postalCode,
        toRead: sign.toRead,
        markerLatLng: sign.markerLatLng,
        createdBy: sign.createdBy,
        notes: sign.notes,
        workType: sign.workType,
      })
      .pipe(
        map((sign: Sign) => {
          return sign;
        })
      );
  }

  updateBoardAndAction(boardId: string, boardActionId: string, data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/${boardId}/board-action/${boardActionId}/update`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  updateBoardAction(id: string, data: any) {
    return this.http
      .patch<any>(`${environment.backendUrl}/v0/board/board-action/${id}`, data)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  createErectAction(sign: Sign) {
    return this.http
      .patch<any>(`${environment.backendUrl}/v0/board/${sign.id}`, {
        houseNumber: sign.houseNumber,
        city: sign.city,
        address: sign.address,
        postalCode: sign.postalCode,
        toRead: sign.toRead,
        markerLatLng: sign.markerLatLng,
        createdBy: sign.createdBy,
        notes: sign.notes,
        workType: sign.workType,
      })
      .pipe(
        map((sign: Sign) => {
          return sign;
        })
      );
  }

  fetchSignById(signId: string) {
    return this.http
      .get<any>(`${environment.backendUrl}/v0/board/${signId}`, {})
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  deleteSign(signId: string) {
    return this.http
      .delete<any>(`${environment.backendUrl}/v0/board/${signId}`)
      .pipe(
        map((sign: Sign) => {
          return sign;
        })
      );
  }

  cancelLastSignAction(signId: string) {
    return this.http
      .delete<any>(`${environment.backendUrl}/v0/board/${signId}/last-sign-action`)
      .pipe(
        map((sign: Sign) => {
          return sign;
        })
      );
  }

  fetchBoardActions(options: any = {}) {
    return this.http
      .get<SignsDTO>(`${environment.backendUrl}/v0/board/board-action`, {
        params: assign(
          {},
          {
            page: 1,
            limit: 9000,
          },
          options,
          {
            relations: ['board', 'toRead', 'scheduleTo'],
          }
        ),
      })
      .pipe(
        map((signs: SignsDTO) => {
          return signs;
        })
      );
  }

  fetchBoardActionScheduleDates(options: any = {}) {
    return this.http
      .get<string[]>(`${environment.backendUrl}/v0/board/board-action/fetch-scheduleat`, {
        params: assign(
          {},
          options,
          {
            relations: ['board', 'toRead', 'scheduleTo'],
          }
        ),
      })
      .pipe(
        map((dates: string[]) => {
          return dates;
        })
      );
  }

  fetchScheduledBoardActions() {
    return this.http
      .get<any>(
        `${environment.backendUrl}/v0/board/board-action/in-scheduled-status`,
        {}
      )
      .pipe(
        map((signs: any) => {
          return signs;
        })
      );
  }

  fetchPendingBoardActions() {
    return this.http
      .get<any>(
        `${environment.backendUrl}/v0/board/board-action/in-pending-status`,
        {}
      )
      .pipe(
        map((signs: any) => {
          return signs;
        })
      );
  }

  fetchSigns(options: any = {}) {
    return this.http
      .get<SignsDTO>(`${environment.backendUrl}/v0/board`, {
        params: assign({}, options, {
          page: 1,
          limit: 9000,
          relations: ['createdBy', 'scheduleTo'],
        }),
      })
      .pipe(
        map((signs: SignsDTO) => {
          return signs;
        })
      );
  }

  fetchUserAllBoard(options: any = {}) {
    return this.http
      .get<SignsDTO>(`${environment.backendUrl}/v0/board/board/self`, {
        params: assign(
          {},
          {
            page: 1,
            limit: 9000,
            relations: ['board', 'toRead', 'actions'],
          },
          options
        ),
      })
      .pipe(
        map((signs: SignsDTO) => {
          return signs;
        })
      );
  }

  fetchAdmins() {
    return this.http
      .get<any>(`${environment.backendUrl}/v0/user?role=admin`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  completeBoardActions(data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/board-action/complete`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  scheduleBoardActions(data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/board-action/schedule`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  unscheduleBoardAction(data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/board-action/unschedule`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  unscheduleBoardActionList(data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/board-action/unschedule-list`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  rejectBoardActionList(data: any) {
    return this.http
      .post<any>(
        `${environment.backendUrl}/v0/board/board-action/reject-list`,
        data
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  getBoardActionPrettyName(item: any) {
    switch (item.actionRequested) {
      case Action.erect:
        return 'Erect';
      case Action.reerect:
        return 'Reerect';
      case Action.change:
        return 'Change';
      case Action.collect:
        return 'Collect';
      case Action.stray:
        return 'Stray';
      default:
        return '';
    }
  }
}
