import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  constructor() {}

  public show(request: HttpRequest<unknown>) {
    let headers = request.headers;
    if (headers.has('X-Client-Loading')) {
      headers.delete('X-Client-Loading');
      this._loading.next(true);
    }
  }

  public hide() {
    delay(state => this._loading.next(state), 250, false);
  }
}
